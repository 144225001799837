import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import * as momentjs from 'moment';

const moment = momentjs;

@Directive({
  selector: '[tMaxDate][formControlName],[tMaxDate][formControl],[tMaxDate][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxDateValidator, multi: true }]
})
export class MaxDateValidator implements Validator {
  @Input() tMaxDate: string;

  validate(c: FormControl): { [key: string]: any } {
    const v: momentjs.Moment = c.value;
    if (this.tMaxDate === '' || this.tMaxDate == null) { return null; }
    if (v == null || !v.isValid()) { return null; }
    return (v > moment(this.tMaxDate, 'YYYY-MM-DD')) ?
      { 'maxDate': { max: moment(this.tMaxDate, 'YYYY-MM-DD'), currentValue: c.value } } : null;
  }
}
