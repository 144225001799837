import { Component, Input, Injector, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BaseControlComponent } from '../base-custom/base-control.component';

@Component({
  selector: 't-slider',
  templateUrl: './custom-slider.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomSliderComponent, multi: true }
  ],
})
export class CustomSliderComponent extends BaseControlComponent implements AfterContentChecked {
  @Input()
  color = 'accent';
  @Input()
  step = 1;
  @Input()
  min = 0;
  @Input()
  max = 100;

  constructor(injector: Injector, private cdRef: ChangeDetectorRef) { super(injector); }

  ngAfterContentChecked(): void {
    if (this.selected == null) {
      this.selected = this.min;
      this.cdRef.detectChanges();
    }
  }
}
