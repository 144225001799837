import { Component, Inject } from '@angular/core';
import { APPCONFIG } from '../common/providers/config.provider';
import { IAppConfig } from '../common/model';

@Component({
  selector: 't-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent {
  constructor(@Inject(APPCONFIG) public appConfig: IAppConfig) { }
}
