import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';
import { IAppConfig } from '../model/app-config';
import { APP_BASE_HREF } from '@angular/common';

const AppConfigToken = new InjectionToken<IAppConfig>('AppConfig');

const configDefaults: IAppConfig = {
    layoutBoxed: false,
    navCollapsed: false,
    navBehind: true,
    fixedHeader: true,
    hasSidebar: true,
    sidebarWidth: 'small',
    autoCloseMobileNav: true,

    production: false,
    hmr: false,
    brand: 'Talk',
    loginFrontUrl: '/login',
    baseFrontUrl: '/',
    serverUrl: window.location.origin + '/api',
    tokenEndpoint: '/auth/token',
    credentialEndpoint: '/auth/credential',
    apiSwaggerUrl: '/service/docs',
    version: '1.0.0',
    clientId: null
};

export { AppConfigToken as APPCONFIG };

@Injectable()
export class ConfigProvider {

    public config: IAppConfig;
    public configLoaded: boolean;

    constructor(@Optional() @Inject(APP_BASE_HREF) public baseHref?: string) {
        if (this.baseHref == null) {
            this.baseHref = document.baseURI != null ? document.baseURI : '/';
        }
        this.configLoaded = false;
        this.config = configDefaults;
    }

    getDefaults() {
        return Object.assign(configDefaults);
    }

    load(): Promise<void> {
        return fetch(this.baseHref + 'config.json')
            .catch((error) => error)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return configDefaults;
                }
            })
            .then((data: IAppConfig) => {
                // se o arquivo de config existir no servidor,
                // sobrepor configurações padrões pelo o que estiver no servidor.
                this.config = {
                    ...configDefaults,
                    ...data
                };
                this.configLoaded = true;
            });
    }

    public getConfig(): IAppConfig {
        return this.config;
    }
}

export function ConfigProviderFactory(provider: ConfigProvider): () => Promise<void> {
    return () => provider.load();
}
