import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { LayoutDataService } from '../../../../services/layout-data.service';
import { LayoutMenu, IAppConfig } from '../../../../common/model';
import { APPCONFIG } from '../../../../common/providers/config.provider';
import { Observable } from 'rxjs';

@Component({
  selector: 't-sidenav-menu',
  styles: [],
  templateUrl: './sidenav-menu.component.html'
})

export class AppSidenavMenuComponent implements OnInit {

  menus: Array<LayoutMenu> = [];
  menusCompleto: Array<LayoutMenu> = [];
  filtro: String = '';

  constructor(private elRef: ElementRef, @Inject(APPCONFIG) public AppConfig: IAppConfig, private dataService: LayoutDataService) { }

  ngOnInit() {
    this.dataService.getMenus().subscribe(x => {
      this.menusCompleto = x.data;
      this.adicionarMenus();
    });
  }

  adicionarMenus() {
    this.menus = [];
    this.menus = this.menus.concat(this.menusCompleto);
  }

  applyFilter(filter: string): any {
    if (filter.length < 1) {
      this.adicionarMenus();
    } else {
      this.menus = this.menusCompleto.filter(item => {
        if (item.subMenus != null) {
          item.subMenus = item.subMenus.filter(submenu => {
            return (submenu.name.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1);
          });
        }
        return ((item.name.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
          (item.subMenus != null && item.subMenus.length > 0));
      });
      this.expandirAll();
    }
  }

  expandirAll() {
    const target = this.elRef.nativeElement.querySelector('.nav');

    for (const children of target.children) {
      children.click();
    }
  }
}
