import { Injectable, Inject } from "@angular/core";
import { AuthenticationService } from "../libs/services/authentication.service";
import { IAppConfig, User, Credential } from "../common/model";
import { StorageProxy } from "../libs/services/storage-proxy.service";
import { APPCONFIG } from "../common/providers/config.provider";

import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Usuario } from "../models/usuario";
import { JwtHelperService } from "@auth0/angular-jwt";
import { UsuarioLogin } from "../models/usuario-login";

@Injectable()
export class AutenticacaoService extends AuthenticationService {


    constructor (protected httpClient: HttpClient,
                 @Inject(APPCONFIG) protected appConfig: IAppConfig) {
        super(httpClient, appConfig);
    }

    get autenticado() {
        return (
          (StorageProxy.get<Credential>('userData') !== undefined &&  StorageProxy.get<Credential>('userData') !== null)
          && (StorageProxy.get<Credential>('userData').tokenType === "Cookie" 
          || (StorageProxy.get<Credential>('userData').accessToken !== undefined))
        );
      }

      isAutenticadoSemToken() {
        return StorageProxy.get<Credential>('userData') && !StorageProxy.get<Credential>('userData').accessToken;
      }

      obterCredenciaisPorCookie(path: string = this.appConfig.credentialEndpoint) {
        return this.httpClient
            .get<any>(path, {
                withCredentials: true
              })
            .pipe(
                tap((result: any) => {
                    const user: User = {
                        id: result.user.id,
                        userLogin: result.user.userLogin,
                        userName: result.user.userName,
                        properties: result.user.properties
                    };

                    StorageProxy.set('userData', Object.assign({}, StorageProxy.get<any>('userData'), {
                        tokenType: result.tokenType,
                        user: user
                    }));
                })
            );
    }

    public obterUsuario(): UsuarioLogin {
        return StorageProxy.get<Credential>('userData').user;
    }

    public obterClienteIdUsuario(): number {
        return this.obterUsuario().properties['clienteId'];
    }
}