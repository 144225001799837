import { Component, Inject, ViewEncapsulation, ViewChild, OnDestroy, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Table } from 'primeng/table';
import { Subscription } from "rxjs";
import { LazyLoadEvent } from "primeng/api";
import { DashboardService } from "../../../services/dashboard.service";
import { IsNullOrEmpty } from '../../../libs/utils/is-null-or-empty';

@Component({
    selector: "app-score-detalhe-modal",
    templateUrl: "./score-detalhe-modal.component.html",
    styleUrls: ["./score-detalhe-modal.component.scss"],
})
export class ScoreDetalheModalComponent implements OnInit, OnDestroy {

    pesquisou: boolean = false;
    model: any = {};
    scoreAlias: string = '';
    totalRecords: number;
    totalFixo: number;
    totalMovel: number;
    totalGeral: number;
    dados: any = [];
    paginaAtual: any = 0;
    tamanhoAtual: any = 11;
    subFiltro: Subscription;

    subUF: Subscription;
    listaUF: Array<any>;

    subDDD: Subscription;
    listaDDD: Array<any>;

    subRangeScore: Subscription;
    listaRangeScore: Array<any>;

    @ViewChild(Table, { static: false }) table: Table;
    constructor(private ref: MatDialogRef<ScoreDetalheModalComponent>,
        private service: DashboardService,
        @Inject(MAT_DIALOG_DATA) public data: { uf: string, ddd: number, rangeScoreId: string, exibirfiltro: boolean }
    ) {

        if(this.data.exibirfiltro === null || this.data.exibirfiltro === undefined)
            this.data.exibirfiltro = false;
        
    }

    ngOnInit() {
		this.obterUF();
        this.obterDDD(this.data.uf);
        this.obterRangeScore();
        this.pesquisar();
	}

    obterUF() {
        if (!this.data.exibirfiltro)
            return;

        this.subUF = this.service
            .obterListaUF()
            .subscribe(result => {
                this.listaUF = result.map((m: any) => ({ 'id': m, 'label': m }));
            });
    }

    obterDDD(uf:any) {
        if (!this.data.exibirfiltro)
            return;

        this.subDDD = this.service
            .obterListaDDD(uf)
            .subscribe(result => {
                this.listaDDD = result.map((m: any) => ({ 'id': m.ddd, 'label': m.ddd }));
            });
    }

    obterRangeScore() {
        if (!this.data.exibirfiltro)
            return;

        this.subRangeScore = this.service
            .obterListaRangeScore()
            .subscribe(result => {
                this.listaRangeScore = result.map((m: any) => ({ 'id': m.id, 'label': `${m.descricao} (${m.rangeInicial} - ${m.rangeFinal})` }));
            });
    }

    filterUFChange(event:any){
        this.data.ddd = null;
        this.obterDDD(event);        
        this.pesquisar();
    }

    filterDDDChange(event:any){
        this.pesquisar();
    }

    filterScoreChange(event:any){
        this.pesquisar();
    }

    pesquisar() {
        this.pesquisou = true;
        this.paginaAtual = 0;
        this.setCurrentPage(0);

        this.subFiltro = this.service
            .getFiltro(`${this.service._api}/scoreDetalhado`, this.data, this.paginaAtual, this.tamanhoAtual)
            .subscribe(result => {
                this.dados = result.data;
                this.scoreAlias = result.scoreAlias;
                this.totalRecords = result.total;
                this.totalFixo = result.totalFixo;
                this.totalMovel = result.totalMovel;
                this.totalGeral = result.totalGeral;
            });
    }

    loadData(event: LazyLoadEvent) {

        if (!this.pesquisou) {

            let data = this.data;
            data['sortField'] = event.sortField;
            data['sortOrder'] = event.sortOrder;

            this.paginaAtual = event.first;
            this.tamanhoAtual = event.rows;

            this.subFiltro = this.service
                .getFiltro(`${this.service._api}/scoreDetalhado`, data, this.paginaAtual, this.tamanhoAtual)
                .subscribe(result => {
                    this.dados = result.data;
                    this.scoreAlias = result.scoreAlias;
                    this.totalRecords = result.total;
                    this.totalFixo = result.totalFixo;
                    this.totalMovel = result.totalMovel;
                    this.totalGeral = result.totalGeral;
                });
        }
        this.pesquisou = false;
    }

    setCurrentPage(n: number) {
        if (this.table)
            this.table.first = n * this.tamanhoAtual;
    }

    close() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subFiltro) { this.subFiltro.unsubscribe(); }
        if (this.subUF) { this.subUF.unsubscribe(); }
        if (this.subDDD) { this.subDDD.unsubscribe(); }
        if (this.subRangeScore) { this.subRangeScore.unsubscribe(); }
    }
}