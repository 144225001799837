import { Component, Input, Inject, InjectionToken } from '@angular/core';


export const PORTAL_DATA = new InjectionToken<{}>('PortalData');

@Component({
    selector: 't-loading',
    templateUrl: './loading.component.html'
})
export class LoadingComponent {
    @Input() diameter: number;
    @Input() mode: string;
    constructor(@Inject(PORTAL_DATA) public data) {
        this.diameter = data.diameter;
        this.mode = data.mode;
    }
}
