import { Component, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BaseControlComponent } from '../base-custom/base-control.component';

@Component({
  selector: 't-radio',
  templateUrl: './custom-radio.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomRadioComponent, multi: true }
  ],
})
export class CustomRadioComponent extends BaseControlComponent {
  
  @Input()
  alias: string;
  @Input()
  value: string;

  @Input()
  required = false;

  @Input()
  disabled: boolean;

  public get data() {
    return this._data;
  }

  @Input()
  public set data(data: Array<any>) {
    this._data = data;
    this.setOptions();
  }

  options = [];
  _data: Array<any> = [];

  constructor(injector: Injector) { super(injector); }

  setOptions() {
    if (!this.data) { return; }
    if (this.options.length === this.data.length) {
      const newValues = this.data.map(item => item[this.value]);
      const oldValues = this.options.map(item => item.value);

      if (newValues.every((value, index) => value === oldValues[index])) { return; }
    }

    this.options = this.data.map(item => ({
      alias: item[this.alias],
      value: item[this.value]
    }));
  }
}
