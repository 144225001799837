import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Subscription } from 'rxjs';
import { DashboardService } from '../../../services/dashboard.service';
import { ILocale } from '@amcharts/amcharts4/.internal/core/utils/Language';
import { MatDialog } from '@angular/material';
import { ScoreDetalheModalComponent } from '../score-detalhe-modal/score-detalhe-modal.component';
import { PieSeries } from '@amcharts/amcharts4/charts';

am4core.useTheme(am4themes_animated);

@Component({
    templateUrl: 'score-dash.component.html',
    styleUrls: ['score-dash.component.scss'],
    selector: "score-dash"
})

export class ScoreDashComponent implements OnInit, OnDestroy {
    @Input()
    idiomaChart: ILocale;
    totalGeral: number;
    scores: Array<any>;
    chart: am4charts.PieChart;
    subScore: Subscription;
    uf: string;


    constructor(private zone: NgZone,
        private dashboardService: DashboardService,
        public dialog: MatDialog) {

    }

    ngOnInit() {
        this.subScore = this.dashboardService.onScoreReceived.subscribe(result => {
            this.uf = result.uf;
            this.dashboardService.obterScore(this.uf).subscribe(result => {
                this.totalGeral = 0;

                result.forEach((score) => {
                    this.totalGeral += score.quantidade;
                });

                this.scores = result;
                this.createChart();
            });
        })
    }

    createChart() {
        setTimeout(() => {
            this.zone.runOutsideAngular(() => {
                this.chart = am4core.create("score", am4charts.PieChart);
                this.chart.data = this.scores;
                this.chart.language.locale = this.idiomaChart;
                this.chart.numberFormatter.numberFormat = "#.0a";

                this.chart.radius = am4core.percent(70);
                this.chart.innerRadius = am4core.percent(40);

                // Add and configure Series
                var pieSeries = this.chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "quantidade";
                pieSeries.dataFields.category = "score";

                pieSeries.hiddenState.properties.startAngle = 0;
                pieSeries.hiddenState.properties.endAngle = 0;


                let label = pieSeries.createChild(am4core.Label);
                label.text = "{values.value.sum}";
                label.horizontalCenter = "middle";
                label.verticalCenter = "middle";
                label.fontSize = 25;


                pieSeries.slices.template.adapter.add("fill", function (fill, series) {
                    if (series.dataItem) {

                        switch (series.dataItem.properties.category) {
                            case "Bom":
                                return am4core.color("#9AFFAD");
                            case "Good":
                                return am4core.color("#9AFFAD");
                            case "Bueno":
                                return am4core.color("#9AFFAD");
                            case "Regular":
                                return am4core.color("#FFF696");
                            case "Ruim":
                                return am4core.color("#FF9292");
                            case "Poor":
                                return am4core.color("#FF9292");
                            case "Mal":
                                return am4core.color("#FF9292");
                            default:
                                return am4core.color("#FFFFFF");
                        }
                    }

                    return am4core.color("FFFFFF");
                });
                pieSeries.slices.template.events.on("hit", (ev) => {
                    let rangeScoreId = ev.target.dataItem.dataContext["rangeScoreId"];
                    this.modalScoreDetalhe(rangeScoreId);
                    //let series = ev.target.dataItem.component as PieSeries;


                    // series.slices.each(function (item) {
                    //     debugger;
                    //     if (item.isActive && item != ev.target) {
                    //         //     item.isActive = false;
                    //         debugger;
                    //     }
                    // })
                });

                pieSeries.ticks.template.disabled = true;
                pieSeries.alignLabels = false;
                pieSeries.labels.template.text = "{value.percent.formatNumber('#.00')}%";
                pieSeries.labels.template.radius = am4core.percent(-23);
                pieSeries.labels.template.fill = am4core.color("black");
                pieSeries.labels.template.fontSize = 11;
                pieSeries.slices.template.tooltipText = "{score}: {quantidade}";
            });
        }, 0);
    }

    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            this.disposeChart();
        });

        if (this.subScore)
            this.subScore.unsubscribe();
    }

    disposeChart() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = undefined;
        }
    }

    modalScoreDetalhe(rangeScoreId: number) {
        let dialogRef = this.dialog.open(ScoreDetalheModalComponent, {
            hasBackdrop: true,
            width: "700px",
            height: "350px",
            data: {
                'exibirfiltro': false,
                'uf': this.uf,
                'rangeScoreId': rangeScoreId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) return;
        });
    }
}