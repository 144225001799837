import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'suffixNumber'
})
export class SuffixNumberPipe implements PipeTransform {

    constructor(public translateService: TranslateService) {

    }

    transform(input: any, args?: any): any {
      if (!input) return 0;
      var exp, rounded,
        suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];
  
      if (Number.isNaN(input)) {
        return null;
      }
  
      if (input < 1000) {
        return input;
      }
  
      exp = Math.floor(Math.log(input) / Math.log(1000));
  
      return ((input / Math.pow(1000, exp)).toLocaleString(this.translateService.currentLang, { minimumFractionDigits: args, maximumFractionDigits: args })) + suffixes[exp - 1];
    }
}