import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AutenticacaoService } from "../services/autenticacao.service";

@Injectable()
export class NaoAutenticadoGuard implements CanActivate {

    constructor(public auth: AutenticacaoService, protected router: Router) { }

    canActivate() {

        if (!this.auth.autenticado) { return true; }
        else if (!this.auth.usuario.properties.primeiroAcesso) {
            this.router.navigate(['/']);
            return false;
        } else {
            this.router.navigate(['/alterarsenha']);
            return false;
        }
    }
}