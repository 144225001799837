import { Component, LOCALE_ID, OnInit } from "@angular/core";
import { DetalheBaseController } from "../../libs/base/detalhe.base.controller";
import { ActivatedRoute } from "@angular/router";
import { ChartService } from "../../services/chart.service";
import { DashboardTerminalService } from "src/app/services/dashboard-terminal.service";
import { Inject } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { formatDate } from "@angular/common";
import { ClienteService } from "src/app/services/cliente.service";
import am4lang from "@amcharts/amcharts4/lang/pt_BR";
import { DashboardTerminalModalComponent } from "./modal/dashboard-terminal-modal.component";
import { MatDialog } from "@angular/material";
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
    templateUrl: 'dashboard-terminal.component.html',
    styleUrls: ['dashboard-terminal.component.scss'],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ]
})

export class DashboardTerminalComponent extends DetalheBaseController implements OnInit {
    filtroResumo: any;
    filtroResumoDetalhadoMes: any;
    filtroResumoDetalhadoDia: any;

    terminal: any;
    terminalMes: any;
    terminalDia: any;

    clientes: any;

    hoje: any;
    maiorQueHojeMes: any = true;
    maiorQueHojeDia: any = true;

    constructor(private route: ActivatedRoute,
        private dashboardTerminalService: DashboardTerminalService,
        private clienteService: ClienteService,
        private dialog: MatDialog,
        @Inject(LOCALE_ID) public locale: string,
        private chartService: ChartService) {
        super(route, dashboardTerminalService);
        this.clientes = [];
        this.terminalMes = [];
        this.terminalDia = [];
    }

    ngOnInit() {
        this.chartService.removerMarcaDagua();

        this.hoje = new Date();
        let dataInicio: Date = new Date();
        let dataFim: Date = new Date();
        
        dataInicio.setMonth(dataInicio.getMonth() - 1);

        this.filtroResumo = {
            dataI: dataInicio,
            dataF: dataFim,
            clienteId: undefined
        }

        this.filtroResumoDetalhadoMes = {
            dataI: dataInicio,
            dataF: dataFim,
        }

        this.filtroResumoDetalhadoDia = {
            dataI: new Date(),
            dataF: new Date(),
        }

        this.clienteService.obterClientes().subscribe(result => {
            this.clientes = result.data;
        })
    }

    ngAfterViewInit() {
        this.pesquisarResumo();
        this.pesquisarResumoDetalhadoMes();
        this.pesquisarResumoDetalhadoDia();
    }

    gerarGrafico() {
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create("resumo", am4charts.XYChart);
        chart.language.locale = am4lang;
    
        chart.data = this.terminal.map((dataItem) => ({
            data: dataItem.data,
            quantidade: dataItem.quantidade,
            limiteRestante: Math.max(dataItem.quantidadeLimite - dataItem.quantidade, 0)
        }));
    
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "data";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
    
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Quantidade";
    
        // Série para Quantidade Consumida
        let series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.name = "Quantidade Consumida";
        series1.dataFields.valueY = "quantidade";
        series1.dataFields.categoryX = "data";
        series1.stacked = true;
        series1.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    
        // Série para Limite Restante
        let series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.name = "Quantidade Restante";
        series2.dataFields.valueY = "limiteRestante";
        series2.dataFields.categoryX = "data";
        series2.stacked = true;
        series2.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    
        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();
        chart.scrollbarX = new am4core.Scrollbar();
    
        // Verificar se deve mostrar gráfico empilhado
        if (this.filtroResumo.clienteId > 0) {
            series1.hidden = false;
            series2.hidden = false;
        } else {
            // Ocultar a série do limite restante ou ajustar conforme necessário
            series1.hidden = false;
            series2.hidden = true;
        }
    }


    novaDataMes(OPERACAO: any) {
        if (OPERACAO == 1) {
            this.filtroResumoDetalhadoMes.dataF.setMonth(this.filtroResumoDetalhadoMes.dataF.getMonth() - 1);
            const data = this.filtroResumoDetalhadoMes.dataF;
            this.filtroResumoDetalhadoMes.dataF = new Date(data);
            this.filtroResumoDetalhadoMes.dataI.setMonth(this.filtroResumoDetalhadoMes.dataI.getMonth() - 1);
            const data2 = this.filtroResumoDetalhadoMes.dataI;
            this.filtroResumoDetalhadoMes.dataI = new Date(data2);
        }

        if (OPERACAO == 2) {
            this.filtroResumoDetalhadoMes.dataF.setMonth(this.filtroResumoDetalhadoMes.dataF.getMonth() + 1);
            const data = this.filtroResumoDetalhadoMes.dataF;
            this.filtroResumoDetalhadoMes.dataF = new Date(data);
            this.filtroResumoDetalhadoMes.dataI.setMonth(this.filtroResumoDetalhadoMes.dataI.getMonth() + 1);
            const data2 = this.filtroResumoDetalhadoMes.dataI;
            this.filtroResumoDetalhadoMes.dataI = new Date(data2);
        }

        if (this.filtroResumoDetalhadoMes.dataF >= this.hoje) {
            this.maiorQueHojeMes = true;
        }
        else {
            this.maiorQueHojeMes = false;
        }

        this.pesquisarResumoDetalhadoMes();
    }

    novaDataDia(OPERACAO: any) {
        if (OPERACAO == 1) {
            this.filtroResumoDetalhadoDia.dataF = new Date(this.filtroResumoDetalhadoDia.dataF.getTime() - 86400000);
            this.filtroResumoDetalhadoDia.dataI = new Date(this.filtroResumoDetalhadoDia.dataI.getTime() - 86400000);
        }

        if (OPERACAO == 2) {
            this.filtroResumoDetalhadoDia.dataF = new Date(this.filtroResumoDetalhadoDia.dataF.getTime() + 86400000);
            this.filtroResumoDetalhadoDia.dataI = new Date(this.filtroResumoDetalhadoDia.dataI.getTime() + 86400000);
        }

        if (this.filtroResumoDetalhadoDia.dataI >= this.hoje) {
            this.maiorQueHojeDia = true;
        }
        else {
            this.maiorQueHojeDia = false;
        }

        this.pesquisarResumoDetalhadoDia();
    }

    pesquisarResumo() {
        if (this.filtroResumo.dataI == undefined) {
            this._alert.show(
                this._translate.instant('dashboardTerminal.dashboard'),
                this._translate.instant('validacao.camposInvalidos'),
                "error"
            );

            return;
        }

        if (this.filtroResumo.dataF == undefined) {
            this._alert.show(
                this._translate.instant('dashboardTerminal.dashboard'),
                this._translate.instant('validacao.camposInvalidos'),
                "error"
            );

            return;
        }

        let filtroResumo = {
            dataI: undefined,
            dataF: undefined,
            clienteId: undefined
        }

        filtroResumo.dataI = formatDate(this.filtroResumo.dataI, 'yyyy-MM-dd', this.locale);
        filtroResumo.dataF = formatDate(this.filtroResumo.dataF, 'yyyy-MM-dd', this.locale);
        filtroResumo.clienteId = this.filtroResumo.clienteId == undefined ? 0 : this.filtroResumo.clienteId;

        this.dashboardTerminalService.obterResumo(filtroResumo).subscribe(result => {
            this.terminal = result;
            this.terminal.map(m => {
                let data = m.data.split('T')[0].split('-');
                m.data = data[2] + '/' + data[1];
            });
            this.gerarGrafico();
        });
    }

    pesquisarResumoDetalhadoMes() {
        let filtroResumo = {
            dataI: undefined,
            dataF: undefined
        }

        filtroResumo.dataI = formatDate(this.filtroResumoDetalhadoMes.dataI, 'yyyy-MM-dd', this.locale);
        filtroResumo.dataF = formatDate(this.filtroResumoDetalhadoMes.dataF, 'yyyy-MM-dd', this.locale);

        this.dashboardTerminalService.obterResumoDetalhado(filtroResumo).subscribe(result => {
            this.terminalMes = result;
        });
    }

    pesquisarResumoDetalhadoDia() {
        let filtroResumo = {
            dataI: undefined,
            dataF: undefined
        }

        filtroResumo.dataI = formatDate(this.filtroResumoDetalhadoDia.dataI, 'yyyy-MM-dd', this.locale);
        filtroResumo.dataF = formatDate(this.filtroResumoDetalhadoDia.dataF, 'yyyy-MM-dd', this.locale);

        this.dashboardTerminalService.obterResumoDetalhado(filtroResumo).subscribe(result => {
            this.terminalDia = result;
        });
    }

    abrirModal(event: any) {
        const dialogRef = this.dialog.open(DashboardTerminalModalComponent, {
            data: { 
                data: event.target.dataItem.dataContext.data,
                quantidade: event.target.dataItem.dataContext.quantidade,
            },
            width: "850px",
			height: "700px",
            panelClass: 'modal'
        });
    }

    limpar() {
        let dataInicio: Date = new Date();
        let dataFim: Date = new Date();
        
        dataInicio.setMonth(dataInicio.getMonth() - 1);

        this.filtroResumo = {
            dataI: dataInicio,
            dataF: dataFim,
            clienteId: undefined
        }
    }
}