import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationService {
  _notificationsService: NotificationsService;

  public opcoes = {
    position: ['top', 'right'],
    timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    maxLength: 100
  };

  constructor(notificationsService: NotificationsService, private translate: TranslateService) {
    this._notificationsService = notificationsService;
  }

  public success(title, text) {
    const translated = this.translate.instant([title, text]);
    this._notificationsService.success(
      translated[title],
      translated[text],
      this.opcoes
    );
  }

  public error(title, text) {
    const translated = this.translate.instant([title, text]);
    this._notificationsService.error(
      translated[title],
      translated[text],
      this.opcoes
    );
  }
}