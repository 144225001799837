import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[tFocus]'
})
export class FocusDirective {
  @Input()
  tFocus: boolean;

  constructor(private element: ElementRef) {

  }
  protected ngOnChanges() {
    this.element.nativeElement.focus();
  }

}
