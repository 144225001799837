import { DetalheBaseController } from '../../../libs/base/detalhe.base.controller';
import { ActivatedRoute, Router } from "@angular/router";
import { UsuarioService } from "src/app/services/usuario.service";
import { Component } from "@angular/core";

@Component({
    templateUrl: 'alterar-senha.component.html'
})

export class AlterarSenhaComponent extends DetalheBaseController {
    constructor(private route: ActivatedRoute,
                private usuarioService: UsuarioService,
                private router: Router) {
        super(route, usuarioService);
        this._service._api = '/api/usuario/';        
    }

    alterar(form) {
        if (!this.validate(form)) {
            return;
        }

        if ((this.model.novaSenha != this.model.confirmacaoSenha)) {
            this._notification.error('telaUsuario.senhaInvalida', 'telaUsuario.senhasDiferentes');
            return;
        }

        this.usuarioService.alterarSenha(this.model.senhaAtual, this.model.novaSenha).subscribe(result => {
            this._notification.success(
                this._translate.instant('telaUsuario.alteracaoTela'),
                this._translate.instant('telaUsuario.alteracaoSenhaSucesso'));
            setTimeout(() => {
                this.router.navigate(['app/dashboard']);
            }, 3000);
        });
    }
}