import { Injectable, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import * as primeng from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { BaseController } from './base.controller';
import { BaseService } from '../services/base.service';
@Injectable()
export class PesquisaBaseController extends BaseController implements AfterViewInit, OnInit, OnDestroy {
    _ativarTitle = 'telaPadrao.ativarTitulo';
    _ativarMessage = 'telaPadrao.ativarMensagem';
    _inativarTitle = 'telaPadrao.inativarTitulo';
    _inativarMessage = 'telaPadrao.inativarMensagem';
    _confirmarButton = 'telaPadrao.confirmar';
    _cancelButton = 'telaPadrao.cancelar';

    totalRecords: number;
    dados: any = [];
    show = false;
    paginaAtual: any = 0;
    tamanhoAtual: any = 10;
    subFiltro: Subscription;
    subFiltroPag: Subscription;
    subAtivar: Subscription;
    subInativar: Subscription;
    nomeRelatorio = '';

    @ViewChild(Table, {static: false}) table: Table;
    constructor(service: BaseService) {
        super(service);
    }

    ngOnInit() {
        this.pesquisarGrid();
    }

    ngAfterViewInit() {
        const msg = localStorage.getItem('msgSucesso');
        if (msg) {
            const alert = JSON.parse(msg);
            this._notification.success(alert.title, alert.message);
            localStorage.removeItem('msgSucesso');
        }
    }

    pesquisarGrid() {
        this.paginaAtual = 0;
        this.pesquisar();
        this.setCurrentPage(0);
    }

    pesquisar() {
        this.subFiltro = this._service.getFiltro(this._service._api, this.model, this.paginaAtual, this.tamanhoAtual).subscribe(result => {
            this.show = true;
            this.dados = result.data;
            this.totalRecords = result.total;
        });
    }

    // Grid Configs
    setCurrentPage(n: number) {
        if (this.table) {
            this.table.first = n * this.tamanhoAtual;
        }
    }

    loadData(event: primeng.LazyLoadEvent) {
        if (this.paginaAtual !== event.first) {
            this.paginaAtual = event.first;
            this.tamanhoAtual = event.rows;

            this.subFiltroPag = this._service.getFiltro(this._service._api, this.model, this.paginaAtual, this.tamanhoAtual)
                .subscribe(result => {
                    this.dados = result.data;
                });
        }
    }

    ativarDialog(id) {
        this._alert.confirmationMessage(this._ativarTitle, this._ativarMessage, this._confirmarButton, this._cancelButton)
            .then((result) => {
                if (result.value) {
                    this.subAtivar = this._service.ativar(id).subscribe(() => {
                        this.pesquisar();
                        this._notification.success(this._sucessoTitle, this._sucessoMessage);
                    });
                }
            });
    }

    inativarDialog(id) {
        this._alert.confirmationMessage(this._inativarTitle, this._inativarMessage, this._confirmarButton, this._cancelButton)
            .then((result) => {
                if (result.value) {
                    this.subInativar = this._service.inativar(id).subscribe(() => {
                        this.pesquisar();
                        this._notification.success(this._sucessoTitle, this._sucessoMessage);
                    });
                }
            });
    }

    ngOnDestroy() {
        if (this.subFiltro) { this.subFiltro.unsubscribe(); }
        if (this.subFiltroPag) { this.subFiltroPag.unsubscribe(); }
        if (this.subAtivar) { this.subAtivar.unsubscribe(); }
        if (this.subInativar) { this.subInativar.unsubscribe(); }
    }
}
