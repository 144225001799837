import { NgModule } from '@angular/core';
import { CapitalizeAllPipe } from './capitalize-all.pipe';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { FileSizeFormatPipe } from './file-size-format.pipe';
import { LowerAllPipe } from './lower-all.pipe';
import { LowerFirstPipe } from './lower-first.pipe';
import { HtmlSanitizerPipe } from './html-sanitizer.pipe';
import { TelefonePipe } from './telefone.pipe';

@NgModule({
    declarations: [
        CapitalizeAllPipe,
        CapitalizeFirstPipe,
        DateFormatPipe,
        FileSizeFormatPipe,
        HtmlSanitizerPipe,
        LowerAllPipe,
        LowerFirstPipe,
        TelefonePipe
    ],
    exports: [
        CapitalizeAllPipe,
        CapitalizeFirstPipe,
        DateFormatPipe,
        FileSizeFormatPipe,
        HtmlSanitizerPipe,
        LowerAllPipe,
        LowerFirstPipe,
        TelefonePipe
    ]
})

export class FormatPipeModule { }
