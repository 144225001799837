import { DependenciesModule } from '../../shared/dependencies.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FiltroDashComponent } from './filtro/filtro-dash.component';
import { TipoDashComponent } from './tipo/tipo-dash.component';
import { RegiaoDashComponent } from './regiao/regiao-dash.component';
import { ScoreDashComponent } from './score/score-dash.component';
import { ResumoDashComponent } from './resumo/resumo-dash.component';
import { ScoreDetalheModalComponent } from './score-detalhe-modal/score-detalhe-modal.component';
import { ResumoTotalMesModalComponent } from './resumo/resumo-total-mes-modal/resumo-total-mes-modal.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatSlideToggleModule, MatButtonToggleModule, MatButtonToggleGroupMultiple } from '@angular/material';

@NgModule({
	imports: [
		DependenciesModule,
		BrowserAnimationsModule,
		MatTooltipModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		//MatButtonToggleGroupMultiple,
		CommonModule
	],
	declarations: [
		DashboardComponent,
		FiltroDashComponent,
		TipoDashComponent,
		RegiaoDashComponent,
		ScoreDashComponent,
		ResumoDashComponent,
		ScoreDetalheModalComponent,
		ResumoTotalMesModalComponent
	],
	exports: [
		DashboardComponent,
		FiltroDashComponent,
		TipoDashComponent,
		RegiaoDashComponent,
		ScoreDashComponent,
		ResumoDashComponent
	],
    providers: [
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}
    ],
	entryComponents: [
		ScoreDetalheModalComponent,
		ResumoTotalMesModalComponent
	]
})
export class DashboardModule {

}
