import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
    selector: '[tMinValue][formControlName],[tMinValue][formControl],[tMinValue][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinValueValidator, multi: true }]
})
export class MinValueValidator implements Validator {
    @Input() tMinValue: any;

    validate(c: FormControl): { [key: string]: any } {
        const v: number = parseInt(c.value, 10);
        const minValue: number = parseInt(this.tMinValue, 10);
        if (minValue == null || isNaN(minValue)) { return null; }
        return (v < minValue) ? { 'minValue': { min: this.tMinValue, currentValue: c.value } } : null;
    }
}
