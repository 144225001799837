import * as validate from 'validate.js';

export enum TipoUsuario {
	Administrador = 1,
	Operador = 2,
	Manager = 3,
    Api = 4,
    Background = 5
}

export class Usuario {
    id: number;
    usuarioTipoId: number;
    login: string;
    senha: string;
    confirmacaoSenha: string;
    nome: string;
    email: string;
    primeiroAcesso: boolean;
    clienteId: number;
    validarSenha: boolean;

    constructor(id?: number, nome?: string, email?: string, usuarioTipoId?: TipoUsuario) {
        this.id = id;
        this.nome = nome;
        this.email = email;
        this.login = email;
        this.usuarioTipoId = usuarioTipoId;
    }
    
    static fromRaw(rawUsuario): Usuario {
		return Object.assign(new Usuario(), rawUsuario);
	}

	validate() {
		validate.validators.semEspacos = function (value) {
			if (!value || value.indexOf(" ") == -1) return null;
			return "^espaco";
        };
        
		validate.validators.senhaIgual = function (value, options, key, usuario) {
			if (usuario.senha != value) return "^senhasDiferentes";
			return null;
        };  
        
        let regras = {
			nome: {
				presence: { allowEmpty: false, message: "^obrigatorio" }
            },
			email: {
                presence: { allowEmpty: false, message: "^obrigatorio" },
				email: { message: "^invalido"}
			},            
			usuarioTipoId: {
				presence: { allowEmpty: false, message: "^obrigatorio" }
            },
            confirmacaoSenha: {
                senhaIgual: this.validarSenha
            }
        };
        
        if (this.validarSenha) {
            regras["senha"] = { presence: { allowEmpty: false, message: "^obrigatorio"}, length: { minimum: 5, message: "^minimo"} };
        }

		return validate.validate(this, regras);
	}
}