export class IndicadorArquivoResumo {
    nomeArquivoEnviado: string;
    quantidadeTotal: number;
    quantidadeEncontrados: number;
    quantidadeNaoEncontrados: number;
    quantidadeInvalidos: number;
}

export class IndicadorArquivoTipo {
    terminalTipoId: number;
    terminalTipoDescricao: string;
    quantidade: number;
}

export class IndicadorArquivoDDD {
    ddd: number;
    uf: string;
    quantidade: number;
}