import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fromJson',
    pure: false
})

export class FromJsonPipe implements PipeTransform {
    transform(value: any): any {
        if (value != null && value !== null) {
            try {
                return JSON.parse(value);
            } catch (ex) {
                return null;
            }
        }
        return null;
    }
}