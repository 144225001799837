import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServicesModule } from '../../libs/services/services.module';
import { DependenciesModule } from '../../shared/dependencies.module'
import { LoginComponent } from './login/login.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';

@NgModule({
    imports: [
        DependenciesModule,
        BrowserAnimationsModule,
        ServicesModule
    ],
    declarations: [
        LoginComponent,
        AlterarSenhaComponent
        // NovaSenhaComponent,
        // EsqueciComponent
    ],
    exports: [
        LoginComponent,
        AlterarSenhaComponent
        // NovaSenhaComponent,
        // EsqueciComponent
    ]
})
export class AutenticacaoModule { }