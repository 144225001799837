import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeAll'
})
export class CapitalizeAllPipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    if (value === null) return '';
    return value.toUpperCase();
  }
}