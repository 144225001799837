import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomRadioComponent } from './custom-radio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomValidateModule } from '../custom-validate/custom-validate.module';
import { DirectiveModule } from '../../directives/directives.module';
import { MatRadioModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    TranslateModule,
    CustomValidateModule,
    ReactiveFormsModule,
    DirectiveModule
  ],
  declarations: [
    CustomRadioComponent
  ],
  exports: [
    CustomRadioComponent,
  ]
})
export class CustomRadioModule { }