import { Component, Input, EventEmitter, Output, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BaseControlComponent } from '../base-custom/base-control.component';

@Component({
  selector: 't-select',
  templateUrl: './custom-select.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomSelectComponent, multi: true }
  ],
})
export class CustomSelectComponent extends BaseControlComponent {
  @Input() alias: string;
  @Input() value: any;
  @Input() multiple = false;
  @Input() noDataLabel = 'telaPadrao.semDados';

  @Input() required = false;
  @Output() add: EventEmitter<any>;
  @Output() remove: EventEmitter<any>;
  @Output() search: EventEmitter<any>;

  public get data() {
    return this._data;
  }

  @Input()
  public set data(data: Array<any>) {
    this._data = data;
    this.setOptions();
  }

  options = [];
  _data: Array<any> = [];

  constructor(injector: Injector) {
    super(injector);
    this.search = new EventEmitter();
    this.add = new EventEmitter();
    this.remove = new EventEmitter();
  }

  searched(val) {
    this.search.emit(val);
  }

  added(val) {
    this.add.emit(val);
  }

  removed(val) {
    this.remove.emit(val);
  }

  setOptions() {
    if (!this.data) { return; }
    if (this.options.length === this.data.length) {
      const newValues = this.data.map(item => item[this.value]);
      const oldValues = this.options.map(item => item.value);

      if (newValues.every((value, index) => value === oldValues[index])) { return; }
    }

    this.options = this.data.map(item => ({
      alias: item[this.alias],
      value: item[this.value]
    }));
  }
}
