import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';
import { DialogService } from './dialog.service';
import { LoadingService } from './loading.service';
import { NotificationService } from './notification.service';
import { ExcelService } from './excel.service';
import { AlertService } from './alert.service';
import { MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { LoadingModule } from '../components/loading/loading.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserDataService } from './user-data.service';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    LoadingModule,
    SimpleNotificationsModule.forRoot(),
    TranslateModule
  ],
  providers: [
    AuthenticationService,
    AlertService,
    BaseService,
    DialogService,
    ExcelService,
    LoadingService,
    NotificationService,
    UserDataService
  ],
  exports: [
    LoadingModule,
    SimpleNotificationsModule
  ]
})

export class ServicesModule {
  static injector: Injector = null;

  constructor(injector: Injector) {
    ServicesModule.injector = injector;
  }
}
