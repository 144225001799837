import { NgModule } from '@angular/core';
import { EChartsDirective } from './echarts.directive';
import { EqualValidator } from './validators/equal-validator.directive';
import { FocusDirective } from './focus.directive';
import { FontAdjustDirective } from './fontadjust.directive';
import { MaxValueValidator } from './validators/max-value-validator.directive';
import { MinValueValidator } from './validators/min-value-validator.directive';
import { MaxDateValidator } from './validators/max-date-validator.directive';
import { MinDateValidator } from './validators/min-date-validator.directive';
import { MaxTimeValidator } from './validators/max-time-validator.directive';
import { MinTimeValidator } from './validators/min-time-validator.directive';

@NgModule({
    declarations: [
        EChartsDirective,
        EqualValidator,
        FocusDirective,
        FontAdjustDirective,
        MaxValueValidator,
        MinValueValidator,
        MaxDateValidator,
        MinDateValidator,
        MaxTimeValidator,
        MinTimeValidator
    ],
    exports: [
        EChartsDirective,
        EqualValidator,
        FocusDirective,
        FontAdjustDirective,
        MaxValueValidator,
        MinValueValidator,
        MaxDateValidator,
        MinDateValidator,
        MaxTimeValidator,
        MinTimeValidator
    ]
})

export class DirectiveModule { }
