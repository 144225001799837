import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowerFirst'
})
export class LowerFirstPipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    if (value === null) { return ''; }
    return value.charAt(0).toLowerCase() + value.slice(1);
  }
}
