import { Input, ViewChild, Injector, OnInit } from '@angular/core';
import { ControlValueAccessor, NgModel, FormControl, NgControl } from '@angular/forms';
import { ValidationError } from '../../../common/model/validation-error';
import { CustomValidateComponent } from '../custom-validate/custom-validate.component';

export class BaseControlComponent implements ControlValueAccessor, OnInit {

  @ViewChild(CustomValidateComponent, {static: false}) validateComponent: CustomValidateComponent;

  @ViewChild('input', {static: false}) input: NgModel;

  @Input() disabled = false;

  @Input() shouldValidate = true;

  @Input() validationErrors: Array<ValidationError>;

  @Input() placeholder = 'telaPadrao.placeholderPadrao';

  selected: any;
  control: FormControl;

  constructor(private injector: Injector) { }

  changed: Function = () => { };
  touched: Function = () => { };

  ngOnInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;

        const markDirtyDefault = this.control.markAsDirty;
        this.control.markAsDirty = () => {
          this.input.control.markAsDirty();
          markDirtyDefault.apply(this.control);
        };

        const markTouchedDefault = this.control.markAsTouched;
        this.control.markAsTouched = () => {
          this.input.control.markAsTouched();
          markTouchedDefault.apply(this.control);
        };
      }
    }, 0);
  }

  _changed($event) {
    if ($event) {
      this.changed($event);
    } else {
      this.changed(null);
    }
  }

  _touched($event) {
    if ($event) {
      this.touched($event);
    } else {
      this.touched(null);
    }
  }

  writeValue(obj: any): void {
    this.selected = obj;
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
