import { Component, OnInit } from "@angular/core";
import { DetalheBaseController } from "../../libs/base/detalhe.base.controller";
import { ActivatedRoute } from "@angular/router";

import { DashboardService } from "../../services/dashboard.service";
import { ILocale } from "@amcharts/amcharts4/.internal/core/utils/Language";
import { ChartService } from "../../services/chart.service";

@Component({
    templateUrl: 'dashboard.component.html',
    styleUrls: ['dashboard.component.scss']    
})

export class DashboardComponent extends DetalheBaseController implements OnInit {    
    idiomaChart: ILocale;

    constructor (private route: ActivatedRoute,
                 private dashboardService: DashboardService,
                 private chartService: ChartService) {
        super(route, dashboardService);

        this.idiomaChart = this.chartService.definirIdiomaCharts();
    } 

    ngOnInit() {

        this.chartService.removerMarcaDagua();
    }

    ngAfterViewInit () {
        this.dashboardService.onPesquisar();
    }  
}