import { Injectable, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BuscaCombo, User } from '../../common/model';
import { NotificationService } from '../services/notification.service';
import { DialogService } from '../services/dialog.service';
import { AuthenticationService } from '../services/authentication.service';
import { BaseService } from '../services/base.service';
import { Subscription } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { ServicesModule } from '../services/services.module';
import { NgForm, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BaseController implements OnInit, AfterViewInit, OnDestroy {
    _submitted: boolean = false;
    _sucessoTitle = 'telaPadrao.sucesso';
    _sucessoMessage = 'telaPadrao.sucessoSalvar';
    _notification: NotificationService;
    _dialog: DialogService;
    _alert: AlertService;
    _usuarioLogado: User;
    _service: BaseService;
    _route: ActivatedRoute;
    _translate: TranslateService;

    model: any = {};

    subTitulo: Subscription;
    constructor(service: BaseService) {
        this._service = service;
        this._route = ServicesModule.injector.get(ActivatedRoute);
        this._alert = ServicesModule.injector.get(AlertService);
        this._dialog = ServicesModule.injector.get(DialogService);
        this._notification = ServicesModule.injector.get(NotificationService);
        const authService = ServicesModule.injector.get(AuthenticationService);
        this._translate = ServicesModule.injector.get(TranslateService);
        this._usuarioLogado = authService.usuario;
        this.setTitulo();
    }

    ngOnInit() { }
    ngAfterViewInit() { }

    private setTitulo() {
        const router = ServicesModule.injector.get(Router);
        const title = ServicesModule.injector.get(Title);

        this.subTitulo = router.events.subscribe(event => {
            const titulo = this.getTitle(router.routerState, router.routerState.root).join(' - ');
            this._translate.get(titulo).subscribe((tituloTraduzido) => title.setTitle(tituloTraduzido));
        });
    }

    private getTitle(state, parent) {
        const data = [];

        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            this._translate.get(parent.snapshot.data.title).subscribe((tituloTraduzido) => data.push(tituloTraduzido));
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }

    public validate(form: NgForm) {
        if (form.invalid) {
            for (const key in form.controls) {
                if (form.controls.hasOwnProperty(key)) {
                    const control: AbstractControl = form.controls[key];
                    control.markAsTouched();
                    control.markAsDirty();
                }
            }
            return false;
        }
        return true;
    }

    move(array, fromIndex, toIndex) {
        array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
        return array;
    }

    isToSearch(
        dados: any,
        totalRegistros: number,
        termo: string = '',
        registrosLength: number = 20,
        termoLength: number = 3): boolean {
        return ((dados.length > 0 && totalRegistros <= registrosLength)
            || (dados.length > 0 && termo.length > 0 && termo.length < termoLength));
    }

    buscaCombo(dadosBusca: BuscaCombo) {
        if (!this.isToSearch(dadosBusca.array, dadosBusca.total, dadosBusca.termo, dadosBusca.registrosLength, dadosBusca.termoLength)) {
            dadosBusca.hookCallback();
        }
    }

	public liveError(property: string, message: string, submit: boolean = false) {
        
        let validation = () => {
            let validationResult = this.model.validate();
            if (!validationResult) return null;
            return validationResult[property] ? validationResult[property][0] == message : false;
        };

        if (submit) {
            if (this._submitted) {
                return validation();
            }
        } else {
            return validation();
        }
    }    

    ngOnDestroy() {
        this.subTitulo.unsubscribe();
    }
}
