import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSliderComponent } from './custom-slider.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomValidateModule } from '../custom-validate/custom-validate.module';
import { DirectiveModule } from '../../directives/directives.module';
import { MatSliderModule, MatChipsModule, GestureConfig } from '@angular/material';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import 'hammerjs';

@NgModule({
  imports: [
    CommonModule,
    MatSliderModule,
    MatChipsModule,
    FormsModule,
    TranslateModule,
    CustomValidateModule,
    ReactiveFormsModule,
    DirectiveModule
  ],
  declarations: [
    CustomSliderComponent
  ], 
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
  ],
  exports: [
    CustomSliderComponent,
  ]
})
export class CustomSliderModule { }