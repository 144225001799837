import { Component, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BaseControlComponent } from '../base-custom/base-control.component';

@Component({
  selector: 't-input',
  templateUrl: './custom-input.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomInputComponent, multi: true }
  ]
})
export class CustomInputComponent extends BaseControlComponent {

  @Input()
  required = false;
  @Input()
  minlength: number = null;
  @Input()
  maxlength: number = null;
  @Input()
  pattern: string = null;
  @Input()
  min: number = null;
  @Input()
  max: number = null;

  private _type = 'text';
  email: boolean;

  get type(): string {
    return this._type;
  }

  @Input()
  set type(type: string) {
    this._type = type;
    this.email = this._type === 'email';
  }

  constructor(injector: Injector) { super(injector); }
}
