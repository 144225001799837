import { Component, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BaseControlComponent } from '../base-custom/base-control.component';

@Component({
  selector: 't-datepicker',
  templateUrl: './custom-datepicker.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomDatepickerComponent, multi: true }
  ],
})
export class CustomDatepickerComponent extends BaseControlComponent {

  @Input()
  min: Date;
  @Input()
  max: Date;
  @Input()
  opened: boolean;
  @Input()
  required = false;

  constructor(injector: Injector) { super(injector); }
}
