import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingService } from '../../services/loading.service';
import { MatProgressSpinnerModule } from '@angular/material';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    OverlayModule,
    PortalModule
  ],
  providers: [LoadingService, { provide: OverlayContainer, useClass: FullscreenOverlayContainer }],
  declarations: [LoadingComponent],
  entryComponents: [LoadingComponent],
  exports: [LoadingComponent]
})
export class LoadingModule { }