import { Injectable } from '@angular/core';
import Swal, { SweetAlertType } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { strictEqual } from 'assert';

@Injectable()
export class AlertService {
  constructor(private translate: TranslateService) { }

  show(title: string, description: string, status: SweetAlertType) {
    const translated = this.translate.instant([title, description]);
    return this.showWithConfiguration({
      title: translated[title],
      text: translated[description],
      type: status,
      showCloseButton: true
    });
  }

  showWithConfiguration(configuration) {
    return Swal.fire(configuration);
  }

  confirmationMessage(
    title: string,
    description: string,
    confirmButtonText: string = 'telaPadrao.confirmar',
    cancelButtonText: string = 'telaPadrao.cancelar'
  ) {
    const translated = this.translate.instant([title, description, confirmButtonText, cancelButtonText]);
    return this.showWithConfiguration({
      title: translated[title],
      text: translated[description],
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: translated[confirmButtonText],
      cancelButtonText: translated[cancelButtonText]
    });
  }
}
