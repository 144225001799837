import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { LoadingComponent, PORTAL_DATA } from '../components/loading/loading.component';


@Injectable()
export class LoadingService {
    overlayRef: OverlayRef;

    constructor(private injector: Injector, public overlay: Overlay) { }

    private createInjector(data): PortalInjector {

      const injectorTokens = new WeakMap<any, any>([
        [PORTAL_DATA, data],
      ]);

      return new PortalInjector(this.injector, injectorTokens);
    }

    open() {
        if (!this.overlayRef) {
            const centerStrategy = new GlobalPositionStrategy().centerHorizontally().centerVertically();
            this.overlayRef = this.overlay.create({
                positionStrategy: centerStrategy,
                hasBackdrop: true,
                disposeOnNavigation: true
            });

            const loadingPortal = new ComponentPortal(
              LoadingComponent,
              null,
              this.createInjector({mode: 'indeterminate', diameter: 60})
            );

            this.overlayRef.attach(loadingPortal);
        }
    }

    close() {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }
}
