import { Component, OnInit, Input, NgZone, OnDestroy } from '@angular/core';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { DownloadService } from 'src/app/services/download.service';
import { BaseController } from '../../../../libs/base/base.controller';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ChartService } from 'src/app/services/chart.service';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-download-step',
  templateUrl: 'download-step.component.html',
  styleUrls: ['download-step.component.scss']
})

export class DownloadStepComponent extends BaseController implements OnInit, OnDestroy {
  scores: Array<any>;
  chart: am4charts.PieChart;

  @Input() terminalArquivo: any;
  constructor(
    private _arquivoService: ArquivoService,
    private _downloadService: DownloadService,
    private zone: NgZone,
    private chartService: ChartService
  ) {
    super(_arquivoService);
  }

  ngOnInit() {
    this.chartService.removerMarcaDagua();    
  }

  obterArquivoEncontrados(terminalArquivoId: number) {
    this._arquivoService.obterArquivoEncontrados(terminalArquivoId).subscribe(
      arquivo => {
        this._downloadService.downloadTxt(arquivo, this._translate.instant('arquivoImportacao.downloadArquivoProcessados'));
      }
    );
  }

  obterArquivoNaoEncontrados(terminalArquivoId: number) {
    this._arquivoService.obterArquivoNaoEncontrados(terminalArquivoId).subscribe(
      arquivo => {
        this._downloadService.downloadTxt(arquivo, this._translate.instant('arquivoImportacao.downloadArquivoNaoProcessados'));
      }
    );
  }

  obterArquivoInvalidos(terminalArquivoId: number) {
    this._arquivoService.obterArquivoInvalidos(terminalArquivoId).subscribe(
      arquivo => {
        this._downloadService.downloadTxt(arquivo, this._translate.instant('arquivoImportacao.downloadArquivoInvalidos'));
      }
    );
  }

  obterArquivoEnviado(terminalArquivoId: number, nomeArquivo: string) {
    this._arquivoService.obterArquivoEnviado(terminalArquivoId).subscribe(
      arquivo => {
        this._downloadService.downloadTxt(arquivo, nomeArquivo);
      }
    );
  }

  public carregar(terminalArquivoId: number) {
    this._arquivoService.obterIndicadorScore(terminalArquivoId).subscribe(result => {
      this.scores = result;
      this.createChart();
    });
  }

  createChart() {
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        this.chart = am4core.create("score", am4charts.PieChart);
        this.chart.hiddenState.properties.opacity = 0;
        this.chart.language.locale = this.chartService.definirIdiomaCharts();        
        this.chart.numberFormatter.numberFormat = "#.0a";
        this.chart.data = this.scores;

        this.chart.radius = am4core.percent(70);
        this.chart.innerRadius = am4core.percent(40);
        this.chart.startAngle = 180;
        this.chart.endAngle = 360;

        let series = this.chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = "quantidade";
        series.dataFields.category = "score";

        series.alignLabels = false;

        series.hiddenState.properties.startAngle = 90;
        series.hiddenState.properties.endAngle = 90;

        series.labels.template.text = "{value.percent.formatNumber('#.00')}%";

        series.slices.template.adapter.add("fill", function (fill, series) {
          if (series.dataItem) {
            switch (series.dataItem.properties.category) {
              case "Bom":
                return am4core.color("#9AFFAD");
              case "Regular":
                return am4core.color("#FFF696");
              case "Ruim":
                return am4core.color("#FF9292");
              case "Sem Score":
                  return am4core.color("#d4f3ff");                
              default:
                return am4core.color("FFFFFF");
            }
          }

          return am4core.color("FFFFFF");
        });

        this.chart.legend = new am4charts.Legend();
        this.chart.legend.labels.template.text = "{score} ({range}): [bold]{quantidade}[/bold]";
        this.chart.legend.valueLabels.template.disabled = true;
        this.chart.legend.labels.template.fontSize = 16;
        this.chart.legend.itemContainers.template.paddingLeft = 50;
        this.chart.legend.itemContainers.template.paddingRight = 50;
      });
    }, 0);
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  disposeChart() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = undefined;
    }
  }
}

