import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys',
  pure: false
})
export class ObjectKeysPipe implements PipeTransform {
  transform(value: any): any {
    if (value != null) {
      return Object.keys(value);
    }
    return null;
  }
}
