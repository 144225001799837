import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Subscription } from 'rxjs';
import { DashboardService } from '../../../services/dashboard.service';
import { ScoreDetalheModalComponent } from '../score-detalhe-modal/score-detalhe-modal.component';
import { ILocale } from '@amcharts/amcharts4/.internal/core/utils/Language';
import { MatDialog } from '@angular/material';

am4core.useTheme(am4themes_animated);

@Component({
	templateUrl: 'regiao-dash.component.html',
	styleUrls: ['regiao-dash.component.scss'],
	selector: "regiao-dash"
})

export class RegiaoDashComponent implements OnInit, OnDestroy {
	@Input()
	idiomaChart: ILocale;
	currentActive: any;
	totalGeral: number;
	regiao: Array<any>;
	ddd = new Array<any>();
	dddCompleta: Array<any>;
	subRegiao: Subscription;
	chart: am4maps.MapChart;
	ufSelecionado: string = '';

	totalFixo: number = 0;
	totalMovel: number = 0;
	totalQuantidade: number = 0;

	constructor(private zone: NgZone,
		private dashboardService: DashboardService,
		public dialog: MatDialog) {

	}

	ngOnInit() {
		this.subRegiao = this.dashboardService.onRegiaoReceived.subscribe(result => {
			this.dashboardService.obterRegiao().subscribe(result => {
				this.regiao = result;
				this.montarListaDDD();
				this.createChart();
			});
		})
	}

	montarListaDDD() {
		this.dddCompleta = new Array<any>();

		this.regiao.forEach(uf => {
			uf.dados.forEach(ddd => {
				this.dddCompleta.push(ddd);
			});
		});

		this.ddd = this.dddCompleta.sort((a: any, b: any) => b.quantidade - a.quantidade);

		this.totalFixo = this.ddd.map(m => m.fixo).reduce((item1, item2) => item1 + item2);
		this.totalMovel = this.ddd.map(m => m.movel).reduce((item1, item2) => item1 + item2);
		this.totalQuantidade = this.ddd.map(m => m.quantidade).reduce((item1, item2) => item1 + item2);
	}

	createChart() {
		setTimeout(() => {
			this.zone.runOutsideAngular(() => {
				this.disposeChart();

				this.chart = am4core.create("regiao", am4maps.MapChart);
				this.chart.geodataSource.url = "https://www.amcharts.com/lib/4/geodata/json/brazilLow.json";
				this.chart.projection = new am4maps.projections.Mercator();
				this.chart.numberFormatter.language.locale = this.idiomaChart;
				this.chart.numberFormatter.numberFormat = "#.0a";

				var polygonSeries = this.createPolygonSeries();

				// Set up heat legend
				let heatLegend = this.chart.createChild(am4maps.HeatLegend);
				heatLegend.series = polygonSeries;
				heatLegend.align = "right";
				heatLegend.width = am4core.percent(25);
				heatLegend.marginRight = am4core.percent(4);
				heatLegend.minValue = 0;
				heatLegend.maxValue = 40000000;
				heatLegend.valign = "bottom";
				heatLegend.fontSize = 11;

				// Set up custom heat map legend labels using axis ranges
				let minRange = heatLegend.valueAxis.axisRanges.create();
				minRange.value = heatLegend.minValue;
				minRange.label.text = "Menos";
				let maxRange = heatLegend.valueAxis.axisRanges.create();
				maxRange.value = heatLegend.maxValue;
				maxRange.label.text = "Mais";

				// Blank out internal heat legend value axis labels
				heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function (labelText) {
					return "";
				});

				// Configure series tooltip
				let polygonTemplate = polygonSeries.mapPolygons.template;

				polygonTemplate.tooltipText = "{name}: {value}";
				polygonTemplate.nonScalingStroke = true;
				polygonTemplate.strokeWidth = 0.5;
				polygonTemplate.propertyFields.id = "id";

				polygonTemplate.applyOnClones = true;
				polygonTemplate.togglable = true;

				polygonTemplate.events.on("hit", (ev) => {
					this.ufSelecionado = '';
					this.mapStatusDefault();

					ev.target.series.chart.zoomToMapObject(ev.target);

					if (this.currentActive !== ev.target) {
						this.filtrarDDD(ev.target.dataItem.dataContext["id"]);
						this.ufSelecionado = ev.target.dataItem.dataContext["id"].split("-").pop();
						if (this.currentActive) {
							this.currentActive.isActive = false;
						}

						this.currentActive = ev.target;
					}
					else {
						this.ufSelecionado = '';
						this.homeAction();
					}

					this.dashboardService.onFiltrarPorUF(this.ufSelecionado);
				});

				let ss = polygonTemplate.states.create("active");
				ss.properties.fill = this.chart.colors.getIndex(4);

				let hs = polygonTemplate.states.create("hover");
				hs.properties.fill = this.chart.colors.getIndex(4);

				this.chart.zoomControl = new am4maps.ZoomControl();
				this.chart.zoomControl.align = "left";

				let homeButton = new am4core.Button();

				homeButton.events.on("hit", () => {
					if (this.currentActive) {
						this.currentActive.isActive = false;
						this.dashboardService.onFiltrarPorUF('');
					}

					this.homeAction();
				});

				homeButton.icon = new am4core.Sprite();
				homeButton.padding(7, 5, 7, 5);
				homeButton.width = 30;
				homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
				homeButton.marginBottom = 10;
				homeButton.parent = this.chart.zoomControl;
				homeButton.insertBefore(this.chart.zoomControl.plusButton);
			})
		}, 0)
	}

	mapStatusDefault(): void {
		if (this.currentActive) {
			this.currentActive.setState("default");
		}
	}

	homeAction(): void {
		this.mapStatusDefault();
		this.currentActive = undefined;
		this.ddd = this.dddCompleta.sort((a: any, b: any) => b.quantidade - a.quantidade);

		this.totalFixo = this.ddd.map(m => m.fixo).reduce((item1, item2) => item1 + item2);
		this.totalMovel = this.ddd.map(m => m.movel).reduce((item1, item2) => item1 + item2);
		this.totalQuantidade = this.ddd.map(m => m.quantidade).reduce((item1, item2) => item1 + item2);

		this.chart.goHome();
	}

	createPolygonSeries(): any {
		var polygonSeries = this.chart.series.push(new am4maps.MapPolygonSeries());

		//Set min/max fill color for each area
		polygonSeries.heatRules.push({
			property: "fill",
			target: polygonSeries.mapPolygons.template,
			min: this.chart.colors.getIndex(1).brighten(1),
			max: this.chart.colors.getIndex(1).brighten(-0.3)
		});

		// Make map load polygon (like country names) data from GeoJSON
		polygonSeries.useGeodata = true;
		polygonSeries.calculateVisualCenter = true;

		let dadosMapa = this.formatarDadosMapa();
		polygonSeries.data = dadosMapa;

		var labelSeries = this.createLabelSeries();

		var ids = ["BR-AC", "BR-AL", "BR-AP", "BR-AM", "BR-BA", "BR-CE", "BR-DF", "BR-ES", "BR-GO", "BR-MA", "BR-MT", "BR-MS", "BR-MG", "BR-PA",
			"BR-PB", "BR-PE", "BR-PI", "BR-PR", "BR-RJ", "BR-RN", "BR-RS", "BR-RO", "BR-RR", "BR-SC", "BR-SP", "BR-SE", "BR-TO"];

		polygonSeries.events.on("datavalidated", function () {
			for (var i = 0; i < ids.length; i++) {
				var polygon = polygonSeries.getPolygonById(ids[i]);

				if (polygon) {
					var label = labelSeries.mapImages.create();
					var state = polygon.dataItem.dataContext["id"].split("-").pop();
					label.latitude = polygon.visualLatitude;
					label.longitude = polygon.visualLongitude;
					label.children.getIndex(0)["text"] = state;
				}
			}
		});

		return polygonSeries;
	}

	createLabelSeries(): any {
		// Configure label series
		var labelSeries = this.chart.series.push(new am4maps.MapImageSeries());
		var labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);
		labelTemplate.horizontalCenter = "middle";
		labelTemplate.verticalCenter = "middle";
		labelTemplate.fontSize = 10;
		labelTemplate.interactionsEnabled = false;
		labelTemplate.nonScaling = true;

		return labelSeries;
	}

	formatarDadosMapa(): Array<any> {
		let dados = new Array<any>();
		this.totalGeral = 0;

		this.regiao.forEach((item) => {
			var total = item.dados.reduce((s, f) => {
				return f.quantidade + s;
			}, 0)

			this.totalGeral += total;

			dados.push({
				"id": item.id,
				"value": total
			});
		});

		return dados;
	}

	filtrarDDD(id) {

		this.ddd = this.regiao.find((f: any) => f.id == id).dados;
		this.totalFixo = this.ddd.map(m => m.fixo).reduce((item1, item2) => item1 + item2);
		this.totalMovel = this.ddd.map(m => m.movel).reduce((item1, item2) => item1 + item2);
		this.totalQuantidade = this.ddd.map(m => m.quantidade).reduce((item1, item2) => item1 + item2);

	}

	disposeChart() {
		if (this.chart) {
			this.chart.dispose();
			this.chart = undefined;
		}
	}

	ngOnDestroy() {
		this.zone.runOutsideAngular(() => {
			this.disposeChart();
		});

		if (this.subRegiao)
			this.subRegiao.unsubscribe();
	}

	modalScoreDetalhe(data: any) {
		let dialogRef = this.dialog.open(ScoreDetalheModalComponent, {
			hasBackdrop: true,
			width: "700px",
			height: "760px",

			data: {
				'exibirfiltro': true,
				'uf': data.uf,
				'ddd': data.ddd,
				'rangeScoreId': null
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result) return;
		});
	}
}
