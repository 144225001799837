import { NgModule } from '@angular/core';
import { DependenciesModule } from '../../shared/dependencies.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { ArquivoListaComponent } from './lista/arquivo.lista.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { ArquivoImportacaoComponent } from './importacao/arquivo.importacao.component';
import { UploadStepComponent } from './importacao/upload-step/upload-step.component';
import { DownloadStepComponent } from './importacao/download-step/download-step.component';
import { ProcessamentoStepComponent } from './importacao/processamento-step/processamento-step.component';

@NgModule({
  imports: [
    DependenciesModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatListModule
  ],
  declarations: [
    ArquivoListaComponent,
    ArquivoImportacaoComponent,
    UploadStepComponent,
    DownloadStepComponent,
    ProcessamentoStepComponent
  ],
  exports: [
    ArquivoListaComponent
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ]
})
export class ArquivoModule { }
