import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { appRoutes } from '../app.routing';
import { BreadcrumbService } from '../libs/components/custom-breadcrumb/breadcrumb.service';

@Injectable()
export class CustomBreadcrumbService implements BreadcrumbService {
    getRoutes(): Routes {
        return appRoutes[2].children;
    }
}
