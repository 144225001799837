import { Directive, ElementRef, Renderer, Input, AfterViewInit, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[tFontAdjust]'
})
export class FontAdjustDirective implements AfterViewInit, OnInit {
  @Input() container: any;

  @Input('tFontAdjust') fittext: any;

  constructor(public el: ElementRef, public renderer: Renderer) {
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setFontSize();
  }


  ngOnInit() {
    this.setFontSize();
  }

  setFontSize() {
    this.el.nativeElement.style.setProperty('font-size', (this.container.clientWidth / 5) + 'px');
  }

  ngAfterViewInit() {

  }
}
