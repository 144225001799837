import { Component, Inject, ViewEncapsulation, ViewChild, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { Table } from 'primeng/table';
import { Subscription } from "rxjs";
import { ConsultaTelefonesService } from "../../../services/consulta-telefones.service";
import { LazyLoadEvent } from "primeng/api";
import { IAppConfig, Credential } from '../../../common/model';
import { APPCONFIG } from '../../../common/providers/config.provider';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@aspnet/signalr';
import { StorageProxy } from '../../../libs/services/storage-proxy.service'
import * as signalR from '@aspnet/signalr';

@Component({
    selector: "app-processamento-modal",
    templateUrl: "./processamento-modal.component.html",
    styleUrls: ["./processamento-modal.component.scss"],
    //providers: [{ useClass: CustomAlertService, provide: CustomAlertService }]
})
export class ProcessamentoModalComponent implements OnDestroy {

    pesquisou: boolean = false;
    model: any = {};
    totalRecords: number;
    dados: any = [];
    paginaAtual: any = 0;
    tamanhoAtual: any = 10;
    subFiltro: Subscription;

    signalRGrupo: string = '';
    subCadastrar: Subscription;

    public hubConnection: HubConnection | undefined;

    @ViewChild(Table, { static: false }) table: Table;
    constructor(private ref: MatDialogRef<ProcessamentoModalComponent>,
        private translate: TranslateService,
        private service: ConsultaTelefonesService,
        @Inject(APPCONFIG) protected appConfig: IAppConfig
    ) {

        let user = StorageProxy.get<Credential>('userData').user.properties["clienteId"];
        this.signalRGrupo = `cliente_${user}`;

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${this.appConfig.serverUrl}/notificacoesBigTalk`)
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.pesquisar();
    }

    pesquisar() {
        this.pesquisou = true;
        this.paginaAtual = 0;
        this.setCurrentPage(0);

        this.subFiltro = this.service
            .getFiltro(`${this.service._api}/obterItensProcessamento`, this.model, this.paginaAtual, this.tamanhoAtual)
            .subscribe(result => {
                this.dados = result.data;
                this.totalRecords = result.total;
                this.escutar();
            });
    }

    loadData(event: LazyLoadEvent) {
        if (!this.pesquisou) {
            this.model.sortField = event.sortField;
            this.model.sortOrder = event.sortOrder;

            this.paginaAtual = event.first;
            this.tamanhoAtual = event.rows;

            this.subFiltro = this.service
                .getFiltro(`${this.service._api}/obterItensProcessamento`, this.model, this.paginaAtual, this.tamanhoAtual)
                .subscribe(result => {
                    this.dados = result.data;
                    this.totalRecords = result.total;
                });
        }
        this.pesquisou = false;

    }

    public escutar() {

        let processarTerminal = () => {
            this.hubConnection.invoke('JoinGroup', this.signalRGrupo);
            this.hubConnection.on('ProcessamentoTerminal', dados => {
                if (dados) {
                    if (this.dados.some((i: any) => (i.terminal == dados.terminal))) {
                        this.hubConnection.off('ProcessamentoTerminal');
                        this.pesquisar();
                    }
                }
            });
        };

        if (this.hubConnection.state == HubConnectionState.Disconnected)
            this.hubConnection.start().then(() => processarTerminal());
        else
            processarTerminal();
    }

    setCurrentPage(n: number) {
        if (this.table) {
            this.table.first = n * this.tamanhoAtual;
        }
    }

    public pararEscutar() {
        if (this.hubConnection.state == HubConnectionState.Connected) {
            this.hubConnection.stop();
        }
    }

    close() {
        this.ref.close();
        this.pararEscutar();
    }

    ngOnDestroy() {
        if (this.subFiltro) { this.subFiltro.unsubscribe(); }
        this.pararEscutar();
    }
}
