import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomToggleComponent } from './custom-toggle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomValidateModule } from '../custom-validate/custom-validate.module';
import { DirectiveModule } from '../../directives/directives.module';
import { MatSlideToggleModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatSlideToggleModule,
    FormsModule,
    TranslateModule,
    CustomValidateModule,
    ReactiveFormsModule,
    DirectiveModule
  ],
  declarations: [
    CustomToggleComponent
  ],
  exports: [
    CustomToggleComponent,
  ]
})
export class CustomToggleModule { }
