export class ResumoDash {
    quantidadeTotal: number;
    quantidadeMes: number;
    quantidadeCelular: number;
    quantidadeFixo: number;
    quantidadeTotalComScore: number;
    quantidadeTotalSemScore: number;
    quantidadeMesNovos: number;
    quantidadeMesAtualizados: number;
    quantidadeWhatsApp: number;
    quantidadeGoogleVC: number;
    quantidadeGoogleRCS: number;
}