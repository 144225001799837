import { BaseService } from '../libs/services/base.service';
import { convertToQueryString } from '../libs/utils/query-string';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class ConsultaTelefonesService extends BaseService {

    _apiTerminalScoreCliente: string = '';
    _apiClienteTerminal: string = '';

    constructor(protected http: HttpClient) {
        super(http);
        this._api = '/api/terminalScore';
        this._apiTerminalScoreCliente = '/api/terminalScoreCliente';
        this._apiClienteTerminal = '/api/clienteTerminal';
    }

    getFiltro(path: string, model: any, pagina: number, tamanho: number): Observable<any> {
        return this.http.get(
            `${this._prefixApi}${path}${convertToQueryString(model, true)}&start=${pagina}&limit=${tamanho}`,
            { responseType: 'json' });
    }

    cadastrarTerminalParaMetralhar(model: any): Observable<any> {
        return this.http.post(`${this._api}/processarTerminal/${model.terminal}`, null,
            { headers: { 'Content-Type': 'application/json; charset=utf-8' }, responseType: 'json' });
    }

    cadastrarTerminalParaReprocessar(terminal: number): Observable<any> {
        return this.http.post(`${this._api}/reprocessarTerminal/${terminal}`, null,
            { headers: { 'Content-Type': 'application/json; charset=utf-8' }, responseType: 'json' });
    }

    verificarSMSAtivo(terminal: number): Observable<any> {
        return this.http.post(`${this._api}/verificarSMSAtivo/${terminal}`, null,
            { headers: { 'Content-Type': 'application/json; charset=utf-8' }, responseType: 'json' });
    }
}