import { Component, Inject, ViewEncapsulation, ViewChild, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { Table } from 'primeng/table';
import { Subscription } from "rxjs";
import { ConsultaTelefonesService } from "../../../services/consulta-telefones.service";
import { LazyLoadEvent } from "primeng/api";
import { IAppConfig, Credential } from '../../../common/model';
import { APPCONFIG } from '../../../common/providers/config.provider';
import { StorageProxy } from '../../../libs//services/storage-proxy.service'
import * as signalR from '@aspnet/signalr';

@Component({
    selector: "app-detalhes-terminal-modal",
    templateUrl: "./detalhes-terminal-modal.component.html",
    styleUrls: ["./detalhes-terminal-modal.component.scss"]
})
export class DetalhesTerminalModalComponent implements OnDestroy {

    pesquisou: boolean = false;
    model: any = {};
    dados: any = [];
    subFiltro: Subscription;

    totalLigacao: number;

    constructor(private ref: MatDialogRef<DetalhesTerminalModalComponent>,
        private translate: TranslateService,
        private service: ConsultaTelefonesService,
        @Inject(APPCONFIG) protected appConfig: IAppConfig,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

        if (!this.isNullOrZero(data.quantidadeAtendido) && !this.isNullOrZero(data.quantidadeNaoAtendido))
            this.totalLigacao = data.quantidadeAtendido + data.quantidadeNaoAtendido;
        else if (!this.isNullOrZero(data.quantidadeAtendido) && this.isNullOrZero(data.quantidadeNaoAtendido))
            this.totalLigacao = data.quantidadeAtendido;
        else if (this.isNullOrZero(data.quantidadeAtendido) && !this.isNullOrZero(data.quantidadeNaoAtendido))
            this.totalLigacao = data.quantidadeAtendido;
        else
            this.totalLigacao = 0;
        
        let user = StorageProxy.get<Credential>('userData').user.properties["clienteId"];
    }

    close() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.subFiltro) { this.subFiltro.unsubscribe(); }
    }

    isNullOrZero(valor: any) {
        return (valor == undefined || valor == null || valor == '' || valor == 0 || valor == '0');
    }
}
