import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CustomBreadcrumbComponent } from './custom-breadcrumb.component';
@NgModule({
  imports: [
    CommonModule,
    BreadcrumbModule
  ],
  declarations: [
    CustomBreadcrumbComponent
  ],
  exports: [
    CustomBreadcrumbComponent
  ]
})
export class CustomBreadcrumbModule { }