import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from './breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 't-breadcrumb',
  templateUrl: './custom-breadcrumb.component.html'
})
export class CustomBreadcrumbComponent implements OnInit {
  breadCrumbCollection: Array<MenuItem> = [];
  @Input() homePath = '/app';
  home = { icon: 'fa fa-home', routerLink: '/app' };

  constructor(private route: ActivatedRoute, private service: BreadcrumbService,
    private translate: TranslateService) { }

  findRouteByPath(path): Route {
    return this.service.getRoutes().find(r => r.path === path);
  }

  ngOnInit() {
    const translates: Array<Observable<any>> = [];

    let route = this.findRouteByPath(this.route.routeConfig.path);
    translates.push(
      this.translate.get(route.data.title).pipe(map(res => ({ label: res })))
    );

    if (this.homePath !== '') {
      this.home.routerLink = this.homePath;
    }

    while (route.data.parent) {
      route = this.findRouteByPath(route.data.parent);
      translates.push(
        this.translate.get(route.data.title).pipe(
          map(res => ({ label: res, routerLink: `${this.homePath}/${route.path}` }))
        )
      );
    }

    forkJoin(translates).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        this.breadCrumbCollection.push(res[i]);
      }
      this.breadCrumbCollection = this.breadCrumbCollection.reverse();
    });
  }
}
