import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowerAll'
})
export class LowerAllPipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    if (value === null) return '';
    return value.toLowerCase();
  }
}