import { Injectable } from '@angular/core';
import { BaseService } from '../libs/services/base.service';
import { convertToQueryString } from '../libs/utils/query-string';
import _ from 'underscore';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SistemaMenu } from '../models/sistema-menu';
import { Usuario } from '../models/usuario';

@Injectable()
export class SistemaMenuService extends BaseService {
    data: any;
    constructor(protected http: HttpClient) {
        super(http);
        this._api = '/api/sistemamenu';
    }

    getSistemaMenu(): Observable<any> {
        return this.http.get<any>(this._api + "/menu");   
    }
}
