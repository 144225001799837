import { Injectable } from '@angular/core';
import _ from 'underscore';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../libs/services/base.service';
import { Usuario } from '../models/usuario';
import { map } from 'rxjs/operators';

@Injectable()
export class UsuarioService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http);
    }

    getById(id: number): Observable<Usuario> {
        return this.get(this._api + id).pipe(map(usuario => Usuario.fromRaw(usuario)));
    }

    criarUsuario(usuario: any): Observable<any> {
        return this.post(this._api + 'salvar', usuario);
    }

    alterarUsuario(usuario: any): Observable<any> {
        return this.put(this._api + 'alterar/' + usuario.id , usuario);        
    }

    alterarSenha(senhaAtual: any, novaSenha: any): Observable<any> {
        return this.put(this._api + 'senha', { senhaAtual: senhaAtual, novaSenha: novaSenha });
    }
}