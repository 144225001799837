export const convertToQueryString = (queryObject, usePrefix = false) => {
  if (!queryObject) { return ''; }

  const formUrlEncoded = Object.keys(queryObject)
    .map(
      prop => {
        if (queryObject[prop] != null) {
          return queryObject[prop] instanceof Date || queryObject[prop]._isAMomentObject
            ? `${prop}=${encodeURIComponent(queryObject[prop].toJSON())}`
            : `${prop}=${encodeURIComponent(queryObject[prop]).trim()}`;
        }
        return '';
      })
    .join('&');

  return usePrefix ? `?${formUrlEncoded}` : formUrlEncoded;
};

export const convertToModel = (queryString) => {
  queryString = queryString.replace(/\?/g, '');
  return JSON.parse('{"' + decodeURI(queryString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
};
