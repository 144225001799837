import {
  Pipe,
  PipeTransform
} from "@angular/core";

@Pipe({
  name: 'countKeys',
  pure: false
})
export class CountKeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    if (value != null)
      return Object.keys(value).length;
    return null;
  }
}
