import { Component, OnInit, Inject } from '@angular/core';
import { APPCONFIG } from '../../../common/providers/config.provider';

import { IAppConfig } from '../../../common/model';
import { LayoutDataService } from '../../../services/layout-data.service';

@Component({
  selector: 't-sidenav',
  styles: [],
  templateUrl: './sidenav.component.html'
})

export class AppSidenavComponent implements OnInit {
  public logoUrl: string;

  constructor(@Inject(APPCONFIG) public appConfig: IAppConfig, public dataService: LayoutDataService) { }

  ngOnInit() {
    this.logoUrl = this.dataService.getLogoPath();
  }

  toggleCollapsedNav() {
    this.appConfig.navCollapsed = !this.appConfig.navCollapsed;
  }
}
