import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardStepComponent } from './wizard-step.component';
import { WizardComponent } from './wizard.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    WizardStepComponent,
    WizardComponent
  ],
  exports: [
    WizardStepComponent,
    WizardComponent
  ]
})
export class WizardModule { }
