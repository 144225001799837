import { Injectable } from '@angular/core';
import { StorageProxy } from './storage-proxy.service';
import jwt_decode from 'jwt-decode';
import { Credential, User } from '../../common/model';

@Injectable()
export class UserDataService {

    constructor() { }

    get autenticado() {
        return (
            StorageProxy.get<Credential>('userData') != null &&
            ((StorageProxy.get<Credential>('userData').accessToken != null &&
                !this.isTokenExpirado(StorageProxy.get<Credential>('userData').accessToken)) ||
                this.temRefreshToken)
        );
    }

    get tokenExpirado() {
        return StorageProxy.get<Credential>('userData') != null && StorageProxy.get<Credential>('userData').accessToken != null
            && this.isTokenExpirado(StorageProxy.get<Credential>('userData').accessToken);
    }

    get temRefreshToken() {
        return StorageProxy.get<Credential>('userData') != null && StorageProxy.get<Credential>('userData').refreshToken != null;
    }

    get usuario(): User {
        if (this.autenticado) { return StorageProxy.get<Credential>('userData').user; }
        return {
            id: null,
            userLogin: null,
            userName: null,
            properties: null
        };
    }

    obterTokenAutenticado() {
        if (!StorageProxy.get<Credential>('userData')) { return undefined; }
        return StorageProxy.get<Credential>('userData').accessToken;
    }

    isTokenExpirado(access_token: any): boolean {
        if (!access_token) { return true; }

        const date = this.obterDataExpiracaoToken(access_token);
        if (date === undefined) { return false; }
        return !(date.valueOf() > new Date().valueOf());
    }

    obterDataExpiracaoToken(token: string): Date {
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) { return undefined; }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }
}
