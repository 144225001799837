import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { ResumoDash } from 'src/app/models/resumo-dash';
import { MatDialog } from '@angular/material';
import { ResumoTotalMesModalComponent } from './resumo-total-mes-modal/resumo-total-mes-modal.component';


@Component({
    templateUrl: 'resumo-dash.component.html',
    styleUrls: ['resumo-dash.component.scss'],
    selector: "resumo-dash"
})

export class ResumoDashComponent implements OnInit, OnDestroy {
    resumo: ResumoDash;
    subResumo: Subscription;

    constructor(private zone: NgZone,
        private dashboardService: DashboardService,
        private translateService: TranslateService,
		public dialog: MatDialog) {

        this.resumo = new ResumoDash();
    }


    ngOnInit() {
        this.subResumo = this.dashboardService.onResumoReceived.subscribe(result => {
            this.dashboardService.obterResumo(result.uf).subscribe(result => {
                this.resumo = result;
            });
        })
    }

    modalResumoTotalMes() {
		let dialogRef = this.dialog.open(ResumoTotalMesModalComponent, {
			hasBackdrop: true,
			width: "800px",
			height: "700px"
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result) return;
		});
	}

    ngOnDestroy() {
        if (this.subResumo)
            this.subResumo.unsubscribe();
    }
}