
import { Injectable, OnInit } from '@angular/core';
import { LayoutMenu, User, LayoutHeaderMenu } from '../common/model';
import { AutenticacaoService } from './autenticacao.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SistemaMenuService } from './sistema-menu.service';
import { SistemaMenu } from '../models/sistema-menu';
import { Observable } from 'rxjs';

@Injectable()
export class LayoutDataService {
    sistemaMenus: Array<SistemaMenu>;

    constructor(private translate: TranslateService,
            private router: Router,
            private autenticacaoService: AutenticacaoService,
            private sistemaMenuService: SistemaMenuService) {
    
    }

    getMenus(): Observable<any> {
        return this.sistemaMenuService.getSistemaMenu();
    }

    // getMenus(): Array<LayoutMenu> {

    //     //return this.sistemaMenuService.getSistemaMenu();

    //     this.sistemaMenuService.getSistemaMenu().subscribe(response => { 
    //         this.sistemaMenus = response; 
    //     });

    //     let menu: Array<LayoutMenu> = [
    //         { id: 1, name: this.translate.instant('menu.dashboard'), icon: 'insert_chart', rota: 'dashboard', hasSubMenu: false, subMenus: [], acao: null },
    //         { id: 2, name: this.translate.instant('menu.usuarios'), icon: 'person', rota: 'usuario', hasSubMenu: false, subMenus: [], acao: null },            
    //         { id: 3, name: this.translate.instant('menu.consultaTelefones'), icon: 'search', rota: 'consultatelefones', hasSubMenu: false, subMenus: [], acao: null },
    //         { id: 4, name: this.translate.instant('menu.importarArquivo'), rota: 'arquivo', icon: 'cloud_upload', hasSubMenu: false, subMenus: [], acao: null },
    //         { id: 5, name: this.translate.instant('menu.documentacao'), icon: 'help', rota: 'docs', hasSubMenu: false, subMenus: [], acao: null }
    //     ];

    //     return menu;
    // }

    getUser(): User {
        return this.autenticacaoService.obterUsuario();
    }

    getLogoPath(): string {
        return 'assets/logo.png';
    }

    getFooterHelpLabel(): string {
        return 'Ajuda';
    }

    getMenuSearchLabel(): string {
        return 'Pesquisar';
    }

    getHeaderContextMenus(): Array<LayoutHeaderMenu> {
        if (this.autenticacaoService.autenticado) {
            return [{
                name: "login.alterarSenha",
                rota: 'alterarsenha',
                acao: null
            }, {
                name: "login.sair",
                rota: null,
                acao: () => {
                    this.autenticacaoService.deslogarUsuario();
                    this.router.navigate(['/login']);
                },
            }];
        }
    }
}
