import { OnInit, AfterViewInit, OnDestroy, Component } from "@angular/core";
import { BaseController } from '../../../libs/base/base.controller'
import { DashboardService } from '../../../services/dashboard.service';

@Component({
    templateUrl: 'filtro-dash.component.html',
    styleUrls: ['filtro-dash.component.scss'],
    selector: "filtro-dash"
})

export class FiltroDashComponent extends BaseController implements OnInit, AfterViewInit {
    dataInicio: Date;
    dataFim: Date;    
    

    constructor(private dashboardService: DashboardService) {
        super(dashboardService);
    }

    ngOnInit () {
        let dataBusca = new Date();
        dataBusca.setDate(dataBusca.getDate() - 1);
        
        this.dataInicio = dataBusca;
        this.dataFim = dataBusca;
    }

    ngAfterViewInit () {
        this.dashboardService.onPesquisar();
    }

    pesquisar () {
        if (!this.validarDatas())
            return;

        this.dashboardService.onPesquisar();
    }

    validarDatas(): boolean {
        if (this.dataInicio > this.dataFim) {
            this._alert.show("dashboard.dataInvalida", "dashboard.dataMaior", "error");
            return false;
        }

        if (this.dataInicio == null || this.dataFim == null) {
            this._alert.show("dashboard.dataInvalida", "dashboard.dataNaoInformada", "error");
            return false;
        }

        return true;
    }
}