import { Component, OnInit } from '@angular/core';
import { PesquisaBaseController } from "../../../libs/base/pesquisa.base.controller";
import { ArquivoService } from '../../../services/arquivo.service';
import { Observable, of } from 'rxjs';
import { NgForm } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-arquivo-lista',
  templateUrl: 'arquivo.lista.component.html',
  styleUrls: ['arquivo.lista.component.scss']
})

export class ArquivoListaComponent extends PesquisaBaseController implements OnInit {
  constructor(
    private _arquivoService: ArquivoService
  ) {
    super(_arquivoService);
    this.tamanhoAtual = 10;
    this.formatarDataPadrao();
  }

  status: any[] = [
    { id: 3, descricao: 'Aguardando Aprovação' },
    { id: 1, descricao: 'Aguardando Leitura' },
    { id: 4, descricao: 'Aguardando Processamento' },
    { id: 6, descricao: 'Concluído' },
    { id: 5, descricao: 'Em Processamento' },
    { id: 2, descricao: 'Lendo Arquivo' },
    { id: 7, descricao: 'Cancelado' }
  ];

  ngOnInit() {
    this.pesquisar();
  }

  loadData(event: LazyLoadEvent) {
    this.paginaAtual = event.first;
    this.tamanhoAtual = event.rows;
    this.model.sortField = event.sortField;
    this.model.sortOrder = event.sortOrder;

    this.obterFiltro();
  }

  pesquisar() {
    this.model.sortField = null;
    this.model.sortOrder = null;
    this.paginaAtual = 0;
    this.setCurrentPage(0);

    this.obterFiltro();
  }

  obterFiltro() {
    this.subFiltroPag = this._service.getFiltro(`${this._service._api}/obterPaginado`, this.model, this.paginaAtual, this.tamanhoAtual)
      .subscribe(result => {
        this.show = true;
        this.dados = result.data;
        this.totalRecords = result.total;
      });
  }

  formatarDataPadrao() {
    this.model.dataCriacaoFim = new Date();
    this.model.dataCriacaoInicio = new Date(this.model.dataCriacaoFim);
    this.model.dataCriacaoInicio.setDate(this.model.dataCriacaoInicio.getDate() - 30);
  }

  reset(form: NgForm) {
    this.show = false;
    form.reset();
    this.formatarDataPadrao();
  }

}


