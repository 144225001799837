import { Component, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BaseControlComponent } from '../base-custom/base-control.component';

@Component({
  selector: 't-timepicker',
  templateUrl: './custom-timepicker.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomTimepickerComponent, multi: true }
  ],
})
export class CustomTimepickerComponent extends BaseControlComponent {

  @Input() required = false;
  @Input() min: number = null;
  @Input() max: number = null;

  constructor(injector: Injector) { super(injector); }
}
