import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[tMaxValue][formControlName],[tMaxValue][formControl],[tMaxValue][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValueValidator, multi: true }]
})
export class MaxValueValidator implements Validator {
  @Input() tMaxValue: any;

  validate(c: FormControl): { [key: string]: any } {
    const v: number = parseInt(c.value, 10);
    const maxValue: number = parseInt(this.tMaxValue, 10);

    if (maxValue == null || isNaN(maxValue)) { return null; }
    return (v > maxValue) ? { 'maxValue': { max: this.tMaxValue, currentValue: c.value } } : null;
  }
}
