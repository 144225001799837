import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Subscription } from 'rxjs';
import { DashboardService } from '../../../services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import { ILocale } from '@amcharts/amcharts4/.internal/core/utils/Language';
import { truncateWithEllipsis } from '@amcharts/amcharts4/.internal/core/utils/Utils';

am4core.useTheme(am4themes_animated);

@Component({
    templateUrl: 'tipo-dash.component.html',
    styleUrls: ['tipo-dash.component.scss'],
    selector: "tipo-dash"
})

export class TipoDashComponent implements OnInit, OnDestroy {
    @Input()
    idiomaChart: ILocale;

    tipos: Array<any>;
    sumarizacao: any = {
        'zero': {
            celular: 0,
            fixo: 0,
            totalTelefones: 0,
            pctCelular: 0,
            pctFixo: 0,
            pctCelularHtml: 0,
            pctFixoHtml: 0
        },
        'outros':{
            celular: 0,
            fixo: 0,
            totalTelefones: 0,
            pctCelular: 0,
            pctFixo: 0,
            pctCelularHtml: 0,
            pctFixoHtml: 0
        }
    }

    // tipoZero: any = {
    //     score: "0",
    //     celular: 0,
    //     fixo: 0,
    //     totalTelefones: 0,
    //     pctCelular: 0,
    //     pctFixo: 0,
    //     pctCelularHtml: 0,
    //     pctFixoHtml: 0,
    //     none: 0
    // };
    subTipos: Subscription;
    chart: am4charts.XYChart;

    exibicaoPorcentagem: string = 'false';

    constructor(private zone: NgZone,
        private dashboardService: DashboardService,
        private translateService: TranslateService) {
    }


    ngOnInit() {
        this.subTipos = this.dashboardService.onTipoReceived.subscribe(result => {
            this.dashboardService.obterTipo(result.uf).subscribe(result => {
                this.tipos = result;
                let zero = result.find((f: any) => (f.score == '0'));
                if (zero == null) {
                    zero = {
                        celular: 0,
                        fixo: 0,
                        none: 0,
                        pctCelular: 0,
                        pctFixo: 0,
                        score: "0"
                    };
                }

                let resto = (100 - (zero.pctCelular + zero.pctFixo)) / 2;

                this.sumarizacao.zero = {
                    celular: zero.celular,
                    fixo: zero.fixo,
                    totalTelefones: (zero.celular + zero.fixo),
                    pctCelular: zero.pctCelular,
                    pctFixo: zero.pctFixo,
                    pctCelularHtml: zero.pctCelular + resto,
                    pctFixoHtml: zero.pctFixo + resto,
                };

                let outros = result.filter((f: any) => (f.score != '0'));
                let celular = outros.map((m: any) => (m.celular)).reduce((a: any, b: any) => a + b, 0);
                let fixo = outros.map((m: any) => (m.fixo)).reduce((a: any, b: any) => a + b, 0);
                let pctCelular = outros.map((m: any) => (m.pctCelular)).reduce((a: any, b: any) => a + b, 0);
                let pctFixo = outros.map((m: any) => (m.pctFixo)).reduce((a: any, b: any) => a + b, 0);

                this.sumarizacao.outros = {
                    celular: celular,
                    fixo: fixo,
                    totalTelefones: (celular + fixo),
                    pctCelular: pctCelular,
                    pctFixo: pctFixo,
                    pctCelularHtml: zero.pctCelular + resto,
                    pctFixoHtml: zero.pctFixo + resto,
                };

                

                this.createChart();
            });
        })

        //this.createChart();
    }

    exibicaoPorcentagemChange() {
        this.createChart();
    }

    createChart() {
        setTimeout(() => {
            this.zone.runOutsideAngular(() => {
                this.disposeChart();

                this.chart = am4core.create("tipo", am4charts.XYChart);
                this.chart.hiddenState.properties.opacity = 0;
                this.chart.titles.template.fontSize = 15;
                this.chart.titles.template.textAlign = "middle";
                this.chart.titles.template.isMeasured = false;
                this.chart.titles.create();
                this.chart.data = this.tipos.filter((f: any) => (f.score != '0'));
                this.chart.language.locale = this.idiomaChart;
                this.chart.numberFormatter.numberFormat = (this.exibicaoPorcentagem == 'true') ? "#.00 '%'" : "#.0a";

                let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "score";
                categoryAxis.renderer.grid.template.disabled = true;
                categoryAxis.renderer.labels.template.disabled = false;
                categoryAxis.renderer.labels.template.fill = am4core.color("black");
                categoryAxis.renderer.labels.template.truncate = true;
                categoryAxis.renderer.labels.template.maxWidth = 80;
                categoryAxis.renderer.minGridDistance = 30;
                categoryAxis.renderer.labels.template.horizontalCenter = "middle";
                categoryAxis.renderer.labels.template.verticalCenter = "middle";
                categoryAxis.tooltip.disabled = true;
                categoryAxis.renderer.labels.template.fontSize = 10;

                let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.min = 0;
                valueAxis.renderer.grid.template.disabled = true;
                valueAxis.renderer.baseGrid.disabled = true;
                valueAxis.renderer.labels.template.disabled = false;
                valueAxis.renderer.labels.template.fill = am4core.color("black");
                valueAxis.renderer.labels.template.fontSize = 10;
                valueAxis.extraMax = 0.1;
                valueAxis.calculateTotals = true;

                this.createSeries((this.exibicaoPorcentagem == 'true') ? "pctCelular" : "celular", this.translateService.instant('dashboard.celular'), false, "#A4FF96"); //#FC7116
                this.createSeries((this.exibicaoPorcentagem == 'true') ? "pctFixo" : "fixo", this.translateService.instant('dashboard.fixo'), true, "#82C4F7"); //#3AB7FF

                var totalSeries = this.chart.series.push(new am4charts.ColumnSeries());
                totalSeries.strokeWidth = 0;
                totalSeries.dataFields.valueY = "none";
                totalSeries.dataFields.categoryX = "score";
                totalSeries.stacked = true;
                totalSeries.hiddenInLegend = true;
                totalSeries.columns.template.strokeOpacity = 1;

                var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
                totalBullet.dy = -10;
                totalBullet.label.text = "{valueY.total}";
                totalBullet.label.hideOversized = false;
                totalBullet.label.truncate = false;
                totalBullet.label.fontSize = 10;
                totalBullet.label.fill = am4core.color("black");
                totalBullet.label.padding(5, 10, 5, 10);

                // Legend
                this.chart.legend = new am4charts.Legend();
                this.chart.legend.position = "right";
                this.chart.legend.valign = "top";
                this.chart.legend.maxHeight = 10;
                this.chart.legend.fontSize = 10;
                this.chart.legend.labels.template.fill = am4core.color("black");
                this.chart.legend.contentAlign = "center"
                this.chart.legend.width = 100;
            })
        }, 0)
    }

    createSeries(field, name, quebra, color, fontcolor = "black") {
        // Set up series
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "score";
        series.sequencedInterpolation = true;
        series.strokeWidth = 0;
        // Make it stacked
        series.stacked = quebra;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

        // Add label
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.fill = am4core.color(fontcolor);
        labelBullet.label.fontSize = 10;
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;

        series.columns.template.fill = color;
        series.showOnInit = true;
        return series;
    }

    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            this.disposeChart();
        });

        if (this.subTipos)
            this.subTipos.unsubscribe();
    }

    disposeChart() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = undefined;
        }
    }
}
