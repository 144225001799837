import { Injectable } from '@angular/core';
import { BaseService } from '../libs/services/base.service';
import { convertToQueryString } from '../libs/utils/query-string';
import _ from 'underscore';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UsuarioTipoService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http);
        this._api = '/api/usuariotipo';
    }

    getTipos(): Observable<any> {
        return this.get(this._api + '/tipos');
    }
}