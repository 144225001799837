import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'numberFormat'
})

export class numberFormatPipe implements PipeTransform {

  constructor(public translateService: TranslateService) {

  }

  transform(input: any, args?: any): any {
    if (!input) return 0;

    return input.toLocaleString(this.translateService.currentLang, { minimumFractionDigits: args, maximumFractionDigits: args });
  }
}