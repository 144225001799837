import { DependenciesModule } from '../../shared/dependencies.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatSlideToggleModule, MatButtonToggleModule } from '@angular/material';
import { DashboardTerminalComponent } from './dashboard-terminal.component';
import { DashboardTerminalModalComponent } from './modal/dashboard-terminal-modal.component';

@NgModule({
	imports: [
		DependenciesModule,
		BrowserAnimationsModule,
		MatTooltipModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		CommonModule
	],
	declarations: [
		DashboardTerminalComponent,
        DashboardTerminalModalComponent,
	],
	exports: [
		DashboardTerminalComponent,
	],
    entryComponents: [
        DashboardTerminalModalComponent,
    ],
	providers: [
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
	],
})
export class DashboardTerminalModule { }