import { Component, OnInit, EventEmitter, ViewChild, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
//import { DefaultValuesService } from "../../common/service/default-values.service";
import { IAppConfig, Credential } from 'src/app/common/model';
import { AlertService } from '../../../libs/services/alert.service';
import { APPCONFIG } from 'src/app/common/providers/config.provider';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";
import { ConfigProvider } from '../../../common/providers/config.provider';


@Component({
    selector: "app-documentos-list",
    templateUrl: "./documentos.list.component.html",
    styleUrls: ["../style.scss", "../swagger-ui.css"],
    providers: [
        //{ useClass: DefaultValuesService, provide: DefaultValuesService }
    ]
})

export class DocumentacaoListComponent implements OnInit {
    swaggerURL: SafeResourceUrl = '';

    constructor(
        private translate: TranslateService,
        public config: ConfigProvider,
        public sanitizer: DomSanitizer,
        @Inject(APPCONFIG) protected appConfig: IAppConfig
    ) {
        this.swaggerURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.appConfig.serverUrl}/docs/index.html`);
     }

    ngOnInit() {
        
    }
}
