import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule, MatButtonModule, MatListModule, MatIconModule } from '@angular/material';
import { ToggleOffcanvasNavDirective } from '../../directives/toggle-offcanvas-nav.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule
  ],
  declarations: [
    AppHeaderComponent,
    ToggleOffcanvasNavDirective
  ],
  exports: [
    AppHeaderComponent
  ]
})
export class HeaderModule { }