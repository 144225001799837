import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatepickerComponent } from './custom-datepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomValidateModule } from '../custom-validate/custom-validate.module';
import { DirectiveModule } from '../../directives/directives.module';
import { MatDatepickerModule, MAT_DATE_FORMATS } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';


@NgModule({
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    FormsModule,
    TranslateModule,
    CustomValidateModule,
    ReactiveFormsModule,
    DirectiveModule
  ],
  declarations: [
    CustomDatepickerComponent
  ],
  exports: [
    CustomDatepickerComponent
  ]
})
export class CustomDatepickerModule { }