import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import * as momentjs from 'moment';

const moment = momentjs;

@Directive({
  selector: '[tMinTime][formControlName],[tMinTime][formControl],[tMinTime][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinTimeValidator, multi: true }]
})
export class MinTimeValidator implements Validator {
  @Input() tMinTime: string;

  validate(c: FormControl): { [key: string]: any } {
    const v: momentjs.Moment = moment(c.value, 'HH:mm');
    if (this.tMinTime === '' || this.tMinTime == null) { return null; }
    if (v == null || !v.isValid()) { return null; }
    return (v.isBefore(moment(this.tMinTime, 'HH:mm'))) ?
      { 'minTime': { min: moment(this.tMinTime, 'HH:mm'), currentValue: c.value } } : null;
  }
}
