import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'telefone' })
export class TelefonePipe implements PipeTransform {
  transform(val, args) {
    let newStr = '';
    if (val != null) {

      if (val.startsWith('0800')) {
        for (let i = 0; i < val.length; i++) {
          if (i === 4) {
            newStr += '-';
          }
          if (i === 7) {
            newStr += '-';
          }
          newStr += val.substr(i, 1);
        }
      } else {
        newStr = '(';
        for (let i = 0; i < val.length; i++) {
          if (i === 2) {
            newStr += ') ';
          }
          if (i === 6) {
            newStr += '-';
          }
          newStr += val.substr(i, 1);
        }
      }
    }
    return newStr;
  }
}
