import { Component, OnInit } from "@angular/core";
import { UsuarioService } from "../../../services/usuario.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioTipo } from "../../../models/usuario-tipo";
import { UsuarioTipoService } from "../../../services/usuario-tipo.service";
import { DetalheBaseController } from "src/app/libs/base/detalhe.base.controller";
import { Usuario } from "src/app/models/usuario";

@Component({
    templateUrl: 'usuario.edit.component.html',
    styleUrls: ['usuario.edit.component.scss']    
})

export class UsuarioEditComponent extends DetalheBaseController implements OnInit {
    usuarioTipos: Array<UsuarioTipo>;
    isEdit = false;

    constructor (private route: ActivatedRoute,
                 private usuarioService: UsuarioService,
                 private usuarioTipoService: UsuarioTipoService,
                 private router: Router) {
        super(route, usuarioService);
        this.model = new Usuario();       
        this._service._api = '/api/usuario/';
    }

    ngOnInit() {
        this.usuarioTipoService.getTipos().subscribe((response) => {
            this.usuarioTipos = response;

            let id = this.route.snapshot.params['id'];
        
            if (id > 0) {
                this.isEdit = true;
                this.model.id = id;
                this.usuarioService.getById(id).subscribe((response) => {
                    this.model = response;
                });
            }
            else this.isEdit = false;

            this.model.validarSenha = !this.isEdit || this.model.alterarSenha;            
        });
    }

    public salvar() {
		this._submitted = true;        
        this.model.validarSenha = !this.isEdit || this.model.alterarSenha;

        if (this.model.validate()) {
            this._alert.show(
                this._translate.instant('telaUsuario.usuario'),
                this._translate.instant('telaPadrao.camposInvalidos'),
                "error"
            );
            return;            
        }

        this.model.login = this.model.email;

        if (this.isEdit) {
            this.usuarioService.alterarUsuario(this.model).subscribe(result => {
                this._notification.success(
                    this._translate.instant('telaUsuario.alteracaoTela'),
                    this._translate.instant('telaUsuario.alteracaoTelaSucesso'));
                setTimeout(() => {
                    this.router.navigate(['app/usuario']);
                }, 3000);
            });
        } else {
            this.model.primeiroacesso = true;

            this.usuarioService.criarUsuario(this.model).subscribe(result => {
                this._notification.success(
                    this._translate.instant('telaUsuario.criacaoTela'),
                    this._translate.instant('telaUsuario.criacaoTelaSucesso'));
                setTimeout(() => {
                    this.router.navigate(['app/usuario']);
                }, 3000);
            });
        }
    }
}