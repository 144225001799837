import { Pipe, PipeTransform } from "@angular/core";
import * as momentjs from 'moment';

const moment = momentjs;
@Pipe({
  name: "dateFormat"
})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date, args?: any): any {
    if (!value) return "";
    if (!args) return moment(value).format("L");
    return moment(value).format(args);
  }
}
