import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AutenticacaoService } from '../services/autenticacao.service';

@Injectable()
export class AutenticadoGuard implements CanActivate {
    constructor(public auth: AutenticacaoService, private router: Router) { }
    canActivate() {
        if (this.auth.autenticado && this.auth.usuario.properties.primeiroAcesso) {
            //this.router.navigate(['/alterarsenha']);
            //return false;
            return true;
        }
        else if (this.auth.autenticado) {
            return true;
        }
        else if (!this.auth.autenticado) {
            this.router.navigate(['/login']);
            return false;
        }
    }
}