import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { CustomListboxModule } from '../components/custom-listbox/custom-listbox.module';
import { LoadingModule } from '../components/loading/loading.module';
import { WizardModule } from '../components/wizard/wizard.module';
import { CustomSelectModule } from '../components/custom-select/custom-select.module';
import { CustomBreadcrumbModule } from '../components/custom-breadcrumb/custom-breadcrumb.module';
import { CustomDatepickerModule } from '../components/custom-datepicker/custom-datepicker.module';
import { CustomInputModule } from '../components/custom-input/custom-input.module';
import { CustomRadioModule } from '../components/custom-radio/custom-radio.module';
import { CustomCheckboxModule } from '../components/custom-checkbox/custom-checkbox.module';
import { CustomLabelModule } from '../components/custom-label/custom-label.module';
import { CustomSliderModule } from '../components/custom-slider/custom-slider.module';
import { CustomToggleModule } from '../components/custom-toggle/custom-toggle.module';
import { CustomTimepickerModule } from '../components/custom-timepicker/custom-timepicker.module';
import { CustomValidateModule } from '../components/custom-validate/custom-validate.module';
import { MAT_DATE_FORMATS } from '@angular/material';
import { CustomTextareaModule } from '../components/custom-textarea/custom-textarea.module';

export const CUSTOM_FORMAT = {
    parse: {
        dateInput: 'L',
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};

@NgModule({
    imports: [
        CustomSelectModule,
        CustomTextareaModule,
        CustomListboxModule,
        CustomBreadcrumbModule,
        LoadingModule,
        WizardModule,
        BrowserModule,
        BrowserAnimationsModule,
        CustomDatepickerModule,
        CustomInputModule,
        CustomRadioModule,
        CustomCheckboxModule,
        CustomLabelModule,
        CustomSliderModule,
        CustomToggleModule,
        CustomTimepickerModule,
        CustomValidateModule
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMAT }
    ],
    declarations: [],
    exports: [
        CustomSelectModule,
        CustomTextareaModule,
        CustomListboxModule,
        CustomBreadcrumbModule,
        LoadingModule,
        WizardModule,
        BrowserModule,
        BrowserAnimationsModule,
        CustomDatepickerModule,
        CustomInputModule,
        CustomRadioModule,
        CustomCheckboxModule,
        CustomLabelModule,
        CustomSliderModule,
        CustomToggleModule,
        CustomTimepickerModule,
        CustomValidateModule
    ]
})
export class BaseDependenciesModule { }
