import { AutenticacaoModule } from "./autenticacao/autenticacao.module";
import { ConsultaTelefonesModule } from "./consulta-telefones/consulta-telefones.module";
import { DocumentacaoModule } from "./documentacao/documentacao.module";
import { ArquivoModule } from "./arquivo/arquivo.module";
//import { MainModule } from "./main/main.module";


export const CustomModuleCollection = [
  AutenticacaoModule,
  ArquivoModule,
  ConsultaTelefonesModule,

  DocumentacaoModule,
  //MainModule
];
