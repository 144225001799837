import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable, from } from 'rxjs';
import { AutenticacaoService } from '../../../services/autenticacao.service';
import { IAppConfig, Credential } from 'src/app/common/model';
import { AlertService } from '../../../libs/services/alert.service';
import { APPCONFIG } from 'src/app/common/providers/config.provider';

@Component({
	templateUrl: 'login.component.html',
	styleUrls: ['login.component.scss']
})

export class LoginComponent {
    model: any = {};
	loading = false;
	error = '';
	subUsuario: Subscription;
	idiomas: any = [
		{ id: 'pt-BR', descricao: 'idioma.portugues', icon: '../assets/images/languages/pt-BR.svg' },
		{ id: 'en', descricao: 'idioma.ingles', icon: '../assets/images/languages/en-US.svg' },
		{ id: 'es', descricao: 'idioma.espanhol', icon: '../assets/images/languages/es.svg' }
	]	

	constructor(
		private router: Router,
		private translate: TranslateService,
		private autenticacaoService: AutenticacaoService,
		protected alert: AlertService,
		private route: ActivatedRoute,
		@Inject(APPCONFIG) protected appConfig: IAppConfig
	) {
		let currentLang = translate.currentLang;
		if (currentLang != null)
			this.model.idioma = currentLang;
		else
			this.model.idioma = 'pt-BR';
	}

	ngOnInit() {

		this.route.queryParams.subscribe(params => {
			if (!params.logado) {
				return;
			}

			this.autenticacaoService.obterCredenciaisPorCookie(`${this.appConfig.serverUrl}/autenticacao/credenciais`)
				.subscribe(
					(res: any) => { this.redirecionar('/dashboard') },
					(error: any) => {
						this.alert.show("Login", "login.autenticacaoFalhou", "error");
					}
				);
		});
	}	

	redirecionar(redirectTo: string) {
		return from([(credential: any) => this.router.navigate(["/app/" + redirectTo])])
			.subscribe((credential: any) => this.router.navigate(["/app/" + redirectTo]));
	}

	onChangeIdoma(idioma) {
		this.translate.use(idioma);
		localStorage.setItem('idioma', idioma);
	}

	login() {
		this.autenticacaoService.deslogarUsuario();

		this.subUsuario = this.autenticacaoService.autenticarLogin({ login: this.model.username, senha: this.model.password }).subscribe(
			(cred: Credential) => {
				if (cred.user.properties.primeiroAcesso) {
					this.router.navigate(['app']);
				} else {
					this.router.navigate(['app']);
				}
			}
		);
	}

	ngOnDestroy() {
		if (this.subUsuario) this.subUsuario.unsubscribe();
	}
}