import { NgModule } from "@angular/core";
import { CountKeysPipe } from "./count-keys.pipe";
import { FilterArrayPipe } from "./filter-array.pipe";
import { FromJsonPipe } from "./from-json.pipe";
import { ObjectKeysPipe } from "./object-keys.pipe";
import { OrderByPipe } from "./order-by.pipe";
import { UniquePipe } from "./unique.pipe";

@NgModule({
    declarations:[
        CountKeysPipe,
        FilterArrayPipe,
        FromJsonPipe,
        ObjectKeysPipe,
        OrderByPipe,
        UniquePipe
    ],
    exports: [
        CountKeysPipe,
        FilterArrayPipe,
        FromJsonPipe,
        ObjectKeysPipe,
        OrderByPipe,
        UniquePipe
    ]
})

export class DataPipeModule { }