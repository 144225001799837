import { Injectable, EventEmitter } from '@angular/core';
import { BaseService } from '../libs/services/base.service';
import { convertToQueryString } from '../libs/utils/query-string';
import _ from 'underscore';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class DashboardService extends BaseService {
    data: any = {};
    onResumoReceived: EventEmitter<any> = new EventEmitter();
    onScoreReceived: EventEmitter<any> = new EventEmitter();
    onTipoReceived: EventEmitter<any> = new EventEmitter();
    onRegiaoReceived: EventEmitter<any> = new EventEmitter();

    constructor(protected http: HttpClient) {
        super(http);
        this._api = '/api/dashboard';
    }


    onPesquisar() {
        this.onResumoReceived.emit({ uf: '' });
        this.onTipoReceived.emit({ uf: '' });
        this.onScoreReceived.emit({ uf: '' });
        this.onRegiaoReceived.emit();
    }

    onFiltrarPorUF(uf: string) {
        this.onResumoReceived.emit({ uf: uf });
        this.onTipoReceived.emit({ uf: uf });
        this.onScoreReceived.emit({ uf: uf });
    }

    obterListaUF() {
        return this.get(this._api + '/uf');
    }

    obterListaDDD(uf: string) {
        if (uf == '') {
            return this.get(this._api + '/ddd?');
        }

        return this.get(this._api + '/ddd?', convertToQueryString({ 'uf': uf }));
    }

    obterListaRangeScore(): Observable<any> {
        return this.http.get<any>(this._api + "/rangeScore");
    }

    obterResumo(uf: string) {
        if (uf == '') {
            return this.get(this._api + '/resumo?');
        }

        return this.get(this._api + '/resumo?', convertToQueryString({ uf: uf }));
    }

    obterScore(uf: string) {
        if (uf == '') {
            return this.get(this._api + '/score?');
        }

        return this.get(this._api + '/score?', convertToQueryString({ uf: uf }));
    }

    obterTipo(uf: string) {
        if (uf == '')
            return this.get(this._api + '/tipo?');

        return this.get(this._api + '/tipo?', convertToQueryString({ uf: uf }));
    }

    obterRegiao() {
        return this.get(this._api + '/regiao?');
    }
}