export function deepMerge(obj1: object, obj2: object) {
    const result = Object.assign({}, obj1, obj2);
    for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
            if (obj1[key] != null && obj2[key] != null && typeof (obj1[key]) === 'object' && typeof (obj2[key]) === 'object') {
                result[key] = deepMerge(obj1[key], obj2[key]);
            }
        }
    }
    return result;
}
