import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomValidateComponent } from './custom-validate.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DataPipeModule } from '../../pipes/data-pipes/data-pipes.module';
import { MatFormFieldModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    TranslateModule,
    DataPipeModule
  ],
  declarations: [
    CustomValidateComponent
  ],
  exports: [
    CustomValidateComponent
  ]
})
export class CustomValidateModule { }