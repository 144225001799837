import { Component, Inject, ViewEncapsulation, ViewChild, OnDestroy, Input, OnInit, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Table } from 'primeng/table';
import { Subscription } from "rxjs";
import { LazyLoadEvent } from "primeng/api";
import { DashboardService } from "../../../../services/dashboard.service";
import { IsNullOrEmpty } from '../../../../libs/utils/is-null-or-empty';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ILocale } from '@amcharts/amcharts4/.internal/core/utils/Language';
import { ChartService } from "../../../../services/chart.service";

@Component({
    selector: "app-resumo-total-mes-modal",
    templateUrl: "./resumo-total-mes-modal.component.html",
    styleUrls: ["./resumo-total-mes-modal.component.scss"],
})
export class ResumoTotalMesModalComponent implements OnInit, OnDestroy {

    idiomaChart: ILocale;
    pesquisou: boolean = false;
    model: any = {};
    totalQuantidadeNovos: number;
    totalQuantidadeAtualizados: number;
    totalQuantidadeTotal:number;
    dados: any = [];
    paginaAtual: any = 0;
    tamanhoAtual: any = 11;
    subFiltro: Subscription;
    chart: am4charts.XYChart;

    @ViewChild(Table, { static: false }) table: Table;
    constructor(private zone: NgZone, private ref: MatDialogRef<ResumoTotalMesModalComponent>, private service: DashboardService,
        private chartService: ChartService) {

        this.idiomaChart = this.chartService.definirIdiomaCharts();
    }

    ngOnInit() {
        this.pesquisar();


    }

    pesquisar() {
        this.pesquisou = true;
        this.paginaAtual = 0;
        this.setCurrentPage(0);

        this.subFiltro = this.service
            .get(`${this.service._api}/resumototalmes`)
            .subscribe(result => {
                this.dados = result;
                this.totalQuantidadeNovos = result.map((m: any) => (m.quantidadeNovos)).reduce((a: any, b: any) => a + b, 0);
                this.totalQuantidadeAtualizados = result.map((m: any) => (m.quantidadeAtualizados)).reduce((a: any, b: any) => a + b, 0);
                this.totalQuantidadeTotal = result.map((m: any) => (m.quantidadeTotal)).reduce((a: any, b: any) => a + b, 0);

                this.createChart();
            });
    }

    createChart() {
        setTimeout(() => {
            this.zone.runOutsideAngular(() => {
                this.disposeChart();
                this.chart = am4core.create("chartdiv", am4charts.XYChart);
                this.chart.numberFormatter.language.locale = this.idiomaChart;
                this.chart.numberFormatter.numberFormat = "#.0a";

                // Add data
                this.chart.data = this.dados;

                // Create axes
                let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());

                // Create value axis
                let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

                // Create series
                let lineSeries = this.chart.series.push(new am4charts.LineSeries());
                lineSeries.dataFields.valueY = "quantidadeTotal";
                lineSeries.dataFields.dateX = "dataAlteracao";
                lineSeries.name = "Telefones";
                lineSeries.strokeWidth = 3;
                lineSeries.strokeDasharray = "5,4";

                // Add simple bullet
                let bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
                bullet.disabled = true;
                bullet.propertyFields.disabled = "disabled";

                let secondCircle = bullet.createChild(am4core.Circle);
                secondCircle.radius = 6;
                secondCircle.fill = this.chart.colors.getIndex(8);


                bullet.events.on("inited", function (event) {
                    animateBullet(event.target.circle);
                })


                function animateBullet(bullet) {
                    let animation = bullet.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);
                    animation.events.on("animationended", function (event) {
                        animateBullet(event.target.object);
                    })
                }

            })
        }, 0)
    }

    setCurrentPage(n: number) {
        if (this.table)
            this.table.first = n * this.tamanhoAtual;
    }

    close() {
        this.ref.close();
    }

    disposeChart() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = undefined;
        }
    }

    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            this.disposeChart();
        });

        if (this.subFiltro) { this.subFiltro.unsubscribe(); }
    }
}