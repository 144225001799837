import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import * as momentjs from 'moment';

const moment = momentjs;

@Directive({
  selector: '[tMinDate][formControlName],[tMinDate][formControl],[tMinDate][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinDateValidator, multi: true }]
})
export class MinDateValidator implements Validator {
  @Input() tMinDate: string;

  validate(c: FormControl): { [key: string]: any } {
    const v: momentjs.Moment = c.value;
    if (this.tMinDate === '' || this.tMinDate == null) { return null; }
    if (v == null || !v.isValid()) { return null; }
    return (v < moment(this.tMinDate, 'YYYY-MM-DD')) ?
      { 'minDate': { min: moment(this.tMinDate, 'YYYY-MM-DD'), currentValue: c.value } } : null;
  }
}
