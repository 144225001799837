export class AuthOptions {
    grant_type: string;
    client_id: string;
    client_secret: string;
    
    username: string;
    password: string;
    refresh_token: string;

    constructor(clientId: string) {      
        this.client_id = clientId;
    }

    setUser(username: string, password: string){
        this.username = username;
        this.password = password;
        this.grant_type = 'password';
    }

    setRefreshToken(refreshToken: string){
        this.refresh_token = refreshToken;
        this.grant_type = 'refresh_token';
    }
}