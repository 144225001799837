import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
    name: 'unique',
    pure: false
})

export class UniquePipe implements PipeTransform {
    transform(value: any, property: string): any {
        if (value != null && property != null) {
            return _.uniq(value, false, function (el1, el2) {
                return el1[property] === el2[property];
            });
        }
        return value;
    }
}