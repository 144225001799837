
import { DependenciesModule } from '../../shared/dependencies.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioListComponent } from './list/usuario.list.component';
import { UsuarioEditComponent } from './edit/usuario.edit.component';

@NgModule({
  imports: [
    DependenciesModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    CommonModule
  ],
  declarations: [
    UsuarioListComponent,
    UsuarioEditComponent
  ],
  exports: [
    UsuarioListComponent,
    UsuarioEditComponent
  ]
})
export class UsuarioModule { }
