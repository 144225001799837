import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { BaseController } from '../../../../libs/base/base.controller';
import { FileUpload } from 'primeng/fileupload';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  selector: 'app-upload-step',
  templateUrl: 'upload-step.component.html',
  styleUrls: ['upload-step.component.scss']
})

export class UploadStepComponent  extends BaseController implements OnInit {
  @Input() terminalArquivo: any;
  @Output() enviouArquivoEvent = new EventEmitter();
  @ViewChild(FileUpload, { static: true }) fu: FileUpload;

  
  
  constructor(
    private _arquivoService: ArquivoService,
    private _downloadService: DownloadService
  ) {
    super(_arquivoService);
  }

  ngOnInit() {
  }

  enviarArquivo() {
    if (this.fu.files.length === 0) {
      this._alert.show(
        'arquivoImportacao.alertArquivoTitulo',
        'arquivoImportacao.alertArquivoTexto',
        'error'
      );
      return;
    }
    this._arquivoService.enviarArquivo(this.fu.files[0])
    .subscribe(
      (terminalArquivoId) => {
        this.enviouArquivoEvent.emit({ terminalArquivoId});
      }
    );
  }

  obterArquivoTemplate() {
    this._arquivoService.obterArquivoTemplate().subscribe(
      arquivo => {
        this._downloadService.downloadTxt(arquivo, this._translate.instant('arquivoImportacao.uploadArquivoTemplate'));
      }
    );
  }
}

