import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import { ComponentType } from '@angular/cdk/portal';

@Injectable()
export class DialogService {

    constructor(public dialog: MatDialog) { }

    open<T>(template: ComponentType<T>, parametros: any = null, width: any = null, height: any = null): MatDialogRef<T, any> {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = parametros;
        config.width = width;
        config.height = height;
        return this.dialog.open(template, config);
    }
}