import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'telefoneMask' })
export class TelefoneMaskPipe implements PipeTransform {
    transform(value: string) : any {
        if (value === undefined || value === null) throw new Error('valor não pode ser nulo!');
        
        if (value.length <= 10) {
            return '('.concat('**', ') ', value.substr(2, 4), '-', value.substr(6, 9), '*');                       
        }

        return '('.concat('**', ')', value.substr(2, 5), '-', value.substr(7, 9), '*');
    }
}