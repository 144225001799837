import { Component, Input } from '@angular/core';

@Component({
  selector: 't-label',
  templateUrl: './custom-label.component.html'
})
export class CustomLabelComponent {

  @Input()
  for: string;

  constructor() { }
}
