import { Component, Input, OnInit } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { ValidationError } from '../../../common/model/validation-error';
import * as _ from 'underscore';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 't-validate',
  templateUrl: './custom-validate.component.html',
})
export class CustomValidateComponent implements OnInit {

  constructor() { }

  errorsText: object = {};
  @Input() input: NgModel;

  // validationErrors
  private _validationErrors = new BehaviorSubject<Array<ValidationError>>([]);
  @Input() set validationErrors(errors: Array<ValidationError>) {
    this._validationErrors.next(errors);
  }
  get validationErrors() {
    return this._validationErrors.getValue();
  }

  // parentControl
  private _parentControl = new BehaviorSubject<FormControl>(new FormControl());
  @Input() set parentControl(control: FormControl) {
    this._parentControl.next(control);
  }
  get parentControl() {
    return this._parentControl.getValue();
  }

  ngOnInit() {
    this._validationErrors.subscribe(() => {
      this.setValidators();
    });
    this._parentControl.subscribe(() => {
      this.setValidators();
    });
  }

  setValidators() {
    if (this.validationErrors != null && this.parentControl != null) {
      const validators = _.map(_.filter(this.validationErrors, (error: ValidationError) => error.validator != null),
        (error: ValidationError) => {
          this.errorsText[error.errorName] = error.hintText;
          return error.validator;
        });

      const validatorsAsync = _.map(_.filter(this.validationErrors, (error: ValidationError) => error.asyncValidator != null),
        (error: ValidationError) => {
          this.errorsText[error.errorName] = error.hintText;
          return error.asyncValidator;
        });

      if (this.input.control) {
        this.input.control.setValidators(validators);
        this.input.control.setAsyncValidators(validatorsAsync);
      }

      this.parentControl.setValidators(validators);
      this.parentControl.setAsyncValidators(validatorsAsync);

      const updateValueAndValidityDefault = this.parentControl.updateValueAndValidity;
      this.parentControl.updateValueAndValidity = () => {
        if (this.input.control) {
          this.input.control.updateValueAndValidity();
        }
        updateValueAndValidityDefault.apply(this.parentControl);
      };
    }
  }


  public validate() {
    this.parentControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }
}
