import { Directive, ElementRef, Input, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import echarts from 'echarts';
import 'echarts/theme/macarons';

@Directive({ selector: '[tECharts]' })

export class EChartsDirective implements AfterViewInit, OnDestroy {
  el: ElementRef;
  constructor(el: ElementRef) {
    this.el = el;
  }

  @Input() EChartsOptions: any;
  private myChart;

  ngAfterViewInit() {
    this.myChart = echarts.init(this.el.nativeElement, 'macarons');
    if (!this.EChartsOptions) { return; }

    this.myChart.setOption(this.EChartsOptions);
  }

  ngOnDestroy() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  }

  resizeChart = (state) => {
    setTimeout(() => { // Important
      if (this.myChart) {
        this.myChart.resize();
      }
    }, 300);
  }

  @HostListener('window:resize')
  onResize() {
    this.resizeChart(true);
  }
}
