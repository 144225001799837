import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServicesModule } from '../../libs/services/services.module';
import { DependenciesModule } from '../../shared/dependencies.module'

import { ConsultaTelefonesListaComponent } from './list/consulta-telefones.lista.component';
import { NgxMaskModule } from 'ngx-mask';
import { TelefoneMaskPipe } from './pipes/telefoneMaskPipe';
import { MatButtonModule, MatButtonToggleModule, MatMenuModule, MatTabsModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { ProcessamentoModalComponent } from './processamento-modal/processamento-modal.component';
import { DetalhesTerminalModalComponent } from './detalhes-terminal-modal/detalhes-terminal-modal.component';
//export const options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
    imports: [
        DependenciesModule,
        BrowserAnimationsModule,
        ServicesModule,
        MatTabsModule,
        MatMenuModule,
        MatButtonModule,
        MatButtonToggleModule,
        NgxMaskModule.forRoot()
    ],
    declarations: [
        ConsultaTelefonesListaComponent,
        TelefoneMaskPipe,
        ProcessamentoModalComponent,
        DetalhesTerminalModalComponent
    ],
    exports: [
        ConsultaTelefonesListaComponent,
        TelefoneMaskPipe
    ],
    providers: [
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}
    ],
    entryComponents:[
        ProcessamentoModalComponent,
        DetalhesTerminalModalComponent
    ]
})
export class ConsultaTelefonesModule { }