import { Component, OnInit } from "@angular/core";
import { UsuarioService } from "../../../services/usuario.service";
import { NgForm, AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UsuarioTipoService } from "../../../services/usuario-tipo.service";
import { UsuarioTipo } from "../../../models/usuario-tipo";
import { PesquisaBaseController } from "src/app/libs/base/pesquisa.base.controller";

@Component({
    templateUrl: 'usuario.list.component.html',
})

export class UsuarioListComponent extends PesquisaBaseController implements OnInit {
	usuarioTipos: Array<UsuarioTipo>;
	
    constructor(private usuarioService: UsuarioService,
				private translateService: TranslateService,
				private usuarioTipoService: UsuarioTipoService) {
        super(usuarioService);
        this._service._api = '/api/usuario/';
    }
    
	ngOnInit() {
        this.usuarioTipoService.getTipos().subscribe((response) => {
			this.usuarioTipos = response;
		});
				
		this.model.ativo = true;
        this.pesquisarGrid();
	}

	pesquisarGrid() {
        super.pesquisarGrid();
	}

    inativarDialog(id) {
        this._inativarTitle = "telaPadrao.excluir"
        this._inativarMessage = 'telaUsuario.excluirUsuario';
        super.inativarDialog(id);
	}
	
	limparGrid() {
		this.model.nome = null;
		this.model.email = null;
		this.model.usuarioTipoId = null;
		super.pesquisarGrid();		
	}
	
	
}
