import { Injectable, EventEmitter } from '@angular/core';
import { BaseService } from '../libs/services/base.service';
import { convertToQueryString } from '../libs/utils/query-string';
import _ from 'underscore';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ClienteService extends BaseService {
    data: any = {};

    constructor(protected http: HttpClient) {
        super(http);
        this._api = '/api/cliente';
    }

    obterClientes() {
        return this.get(this._api + '?ativo=true');
    }
}