import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { MatTabGroup } from '@angular/material';
import { ProcessamentoStepComponent } from './processamento-step/processamento-step.component';
import { DownloadStepComponent } from './download-step/download-step.component';

@Component({
  selector: 'app-arquivo-importacao',
  templateUrl: 'arquivo.importacao.component.html',
  styleUrls: ['arquivo.importacao.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ArquivoImportacaoComponent implements OnInit {
  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
  @ViewChild('processamentoStep', { static: false }) processamentoStep: ProcessamentoStepComponent;
  @ViewChild('downloadStep', { static: false }) downloadStep: DownloadStepComponent;

  terminalArquivo: any;
  stepUpload = 0;
  stepProcessamento = 1;
  stepDownload = 2;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _arquivoService: ArquivoService
  ) {
  }

  ngOnInit() {
    if (this.route.snapshot.data['type'] === 'edit') {
      this.carregar();
    }
  }

  carregarRota($event) {
    this.router.navigate(['/app/arquivo/' + $event.terminalArquivoId.toString()]);
  }

  carregar() {
    this._arquivoService.getById(this.route.snapshot.params['id']).subscribe((terminalArquivo) => {
      this.terminalArquivo = terminalArquivo;
      this.selecionarStep(terminalArquivo);
    });
  }

  selecionarStep(terminalArquivo: any) {
    switch (terminalArquivo.terminalArquivoStatusId) {
      case 3:
        /*Este case não tem brake de propósito!!*/
        this.processamentoStep.obterIndicadorProcessamento(terminalArquivo.id);
      case 1:
      case 2:
      case 4:
      case 5: {
        this.processamentoStep.percentualConclusao = 0;
        this.tabGroup.selectedIndex = this.stepProcessamento;
        this.habilitarTabs(this.stepProcessamento);
        break;
      }
      case 6: {
        this.tabGroup.selectedIndex = this.stepDownload;
        this.habilitarTabs(this.stepDownload);
        this.downloadStep.carregar(terminalArquivo.id);
        break;
      }
      case 7: {
        this.router.navigate(['/app/arquivo']);
        break;
      }
      default: {
        break;
      }
    }
  }

  habilitarTabs(selectedIndex: number) {
    let i = 0;
    this.tabGroup._tabs.toArray().forEach(function (tab) {
      if (i === selectedIndex) {
        tab.disabled = false;
      } else {
        tab.disabled = true;
      }
      i++;
    });
  }
}