import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSidenavComponent } from './sidenav.component';
import { AutoCloseMobileNavDirective } from '../../directives/auto-close-mobile-nav.directive';
import { AppSidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { HighlightActiveItemsDirective } from '../../directives/highlight-active-items.directive';
import { AppendSubmenuIconDirective } from '../../directives/append-submenu-icon.directive';
import { AccordionNavDirective } from '../../directives/accordion-nav.directive';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatButtonModule,
    TranslateModule
  ],
  declarations: [
    AppSidenavComponent,
    AppSidenavMenuComponent,
    AutoCloseMobileNavDirective,
    HighlightActiveItemsDirective,
    AppendSubmenuIconDirective,
    AccordionNavDirective
  ],
  exports: [
    AppSidenavComponent
  ]
})
export class SidenavModule { }