import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { PesquisaBaseController } from '../../../libs/base/pesquisa.base.controller';

import { ConsultaTelefonesService } from '../../../services/consulta-telefones.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IsNullOrEmpty } from '../../../libs/utils/is-null-or-empty';
import { Subscription } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { MatDialog } from '@angular/material';
import { ProcessamentoModalComponent } from '../processamento-modal/processamento-modal.component';
import { DetalhesTerminalModalComponent } from '../detalhes-terminal-modal/detalhes-terminal-modal.component'

import { IAppConfig, Credential } from '../../../common/model';
import { APPCONFIG } from '../../../common/providers/config.provider';
import { AlertService } from '../../../libs/services/alert.service';
import { StorageProxy } from '../../../libs/services/storage-proxy.service';

@Component({
    templateUrl: 'consulta-telefones.lista.component.html',
    styleUrls: ["consulta-telefones.lista.component.scss"],
})

export class ConsultaTelefonesListaComponent extends PesquisaBaseController implements OnInit, OnDestroy {

    private CELLPHONE = '(00) 0 0000-0000';
    private LANDLINE = '(00) 0000-0000';

    phoneMask = this.CELLPHONE;
    previusLength = 0;

    situacaoTerminal: number = 0;
    pesquisou: boolean = false;
    signalRGrupo: string = '';
    subCadastrar: Subscription;

    public hubConnection: HubConnection | undefined;

    constructor(private route: ActivatedRoute,
        private service: ConsultaTelefonesService,
        private translate: TranslateService,
        private alert: AlertService,
        public dialog: MatDialog,
        @Inject(APPCONFIG) protected appConfig: IAppConfig) {
        super(service);

        let user = StorageProxy.get<Credential>('userData').user.properties["clienteId"];
        this.signalRGrupo = `cliente_${user}`;

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${this.appConfig.serverUrl}/notificacoesBigTalk`)
            .configureLogging(signalR.LogLevel.Information)
            .build();
    }

    ngOnInit() {
        let terminal = this.route.snapshot.params['terminal'];

        if (terminal > 0) {
            this.phoneMask = (terminal.length <= 10) ? this.LANDLINE : this.CELLPHONE;
            this.model.terminal = terminal;
            this.pesquisar();
        }
        else {
            this.buscarInputPesquisa();
        }
    }

    pesquisarGrid1(form: NgForm) {
        if (!this.validate(form))
            return;

        this.pesquisar();
    }

    onPhoneChanged() {
        if (this.model.terminal.length <= 10 && this.phoneMask === this.CELLPHONE) {
            this.phoneMask = this.LANDLINE;
        }
        else if (this.model.terminal.length === 10 && this.phoneMask === this.LANDLINE && this.previusLength === 10) {
            this.phoneMask = this.CELLPHONE;
        }

        this.previusLength = this.model.terminal.length;
    }

    changeMask(terminal: any) {
        if (terminal.length <= 10 && this.phoneMask === this.CELLPHONE) {
            this.phoneMask = this.LANDLINE;
        }
        else if (terminal.length === 11 && this.phoneMask === this.LANDLINE && this.previusLength === 11) {
            this.phoneMask = this.CELLPHONE;
        }
    }

    pesquisar() {
        const valido = this.validarTelefone();
        
        if (!valido) {
            return;
        }

        this.pesquisou = true;
        this.paginaAtual = 0;
        this.setCurrentPage(0);
        
        this.subFiltro = this.service
            .getFiltro(`${this._service._api}/obterPaginado`, this.model, this.paginaAtual, this.tamanhoAtual)
            .subscribe(result => {

                if (result.situacaoTerminal != 5) {

                    this.show = (result.situacaoTerminal == 1 || result.situacaoTerminal == 2 || result.situacaoTerminal == 4);
                    this.situacaoTerminal = result.situacaoTerminal;
                    this.dados = result.data;
                    this.totalRecords = result.total;

                    if (result.situacaoTerminal == 3)
                        this.terminalNaoExisteBigTalk();

                    if (result.situacaoTerminal == 4) {
                        console.log("Escutando ProcessamentoTerminal");
                        this.escutar(this.model.terminal);
                    }

                    if (result.situacaoTerminal == 4) {
                        console.log("Escutando ProcessamentoTerminal");
                        this.escutar(this.model.terminal);
                    }

                    if (this.totalRecords > 0) {
                        this.gravarInputPesquisa();
                    }

                } else {
                    this.warnNotification("telaConsultaTelefones.atencao", "telaConsultaTelefones.consultasDiariasExcedeuLimite");
                }
            });
    }

    loadData(event: LazyLoadEvent) {
        if (!this.pesquisou) {
            this.model.sortField = event.sortField;
            this.model.sortOrder = event.sortOrder;

            this.paginaAtual = event.first;
            this.tamanhoAtual = event.rows;

            this.subFiltro = this.service.getFiltro(`${this._service._api}/obterPaginado`, this.model, this.paginaAtual, this.tamanhoAtual).subscribe(result => {
                this.dados = result.data;
            });
        }
        this.pesquisou = false;
    }

    public warnNotification(title, text) {
        const translated = this.translate.instant([title, text]);
        this._notification._notificationsService.warn(
            translated[title],
            translated[text],
            this._notification.opcoes
        );
    }

    terminalNaoExisteBigTalk() {
        this.alert.confirmationMessage("telaPadrao.atencao", "telaConsultaTelefones.terminalNaoExisteBigTalk", "telaConsultaTelefones.processar", "telaPadrao.cancelar")
            .then((result) => {
                if (result.value) {
                    this.subCadastrar = this.service.cadastrarTerminalParaMetralhar(this.model).subscribe((result) => {
                        this.show = true;
                        this.situacaoTerminal = result.situacaoTerminal;
                        this.dados = result.data;
                        this.totalRecords = result.total;
                        this._notification.success(this._sucessoTitle, this._sucessoMessage);

                        console.log("Escutando ProcessamentoTerminal");
                        this.escutar(this.model.terminal);
                    });
                } else {
                    this.model.terminal = null;
                    this.pesquisar();
                }
            });
    }

    public escutar(terminal: any) {
        if (this.hubConnection.state == HubConnectionState.Disconnected)
            this.hubConnection.start().then(() => this.processarTerminal(terminal));
        else
            this.processarTerminal(terminal);
    }

    public processarTerminal(terminal: any) {
        this.hubConnection.invoke('JoinGroup', this.signalRGrupo);
        this.hubConnection.on('ProcessamentoTerminal', dados => {

            if (dados) {
                if (dados.terminal == terminal) {

                    this.phoneMask = (terminal.length <= 10) ? this.LANDLINE : this.CELLPHONE;
                    this.model.terminal = terminal;
                    this.pesquisar();
                    this.hubConnection.off('ProcessamentoTerminal');
                }
            }
        });
    }

    public pararEscutar() {
        if (this.hubConnection.state == HubConnectionState.Connected) {
            //this.hubConnection.invoke('LeaveGroup', this.signalRGrupo);
            this.hubConnection.stop();
        }
    }

    limparGrid(form: NgForm) {
        this.model["terminal"] = null;
        this.dados = null;
        this.pesquisarGrid1(form);
        this.limparInputPesquisa();
    }

    // scoreClass(score: number) {
    //     if (score >= 0 && score <= 29)
    //         return 'scoreRuim';
    //     else if (score >= 30 && score <= 69)
    //         return 'scoreRegular';
    //     else if (score >= 70 && score <= 100)
    //         return 'scoreBom';
    //     else
    //         return '';
    // }

    reprocessarTerminal(terminal: number) {
        this.alert.confirmationMessage("telaPadrao.atencao", "telaConsultaTelefones.reprocessarMensagem", "telaConsultaTelefones.reprocessar", "telaPadrao.cancelar")
            .then((result) => {
                if (result.value)
                    this.subCadastrar = this.service.cadastrarTerminalParaReprocessar(terminal).subscribe((result) => {

                        this.show = true;
                        this.situacaoTerminal = result.situacaoTerminal;
                        this.dados = result.data;
                        this.totalRecords = result.total;
                        this._notification.success(this._sucessoTitle, this._sucessoMessage);

                        console.log("Escutando ProcessamentoTerminal");
                        this.escutar(this.model.terminal);

                    });
            });
    }

    ngOnDestroy() {
        if (this.subCadastrar) { this.subCadastrar.unsubscribe(); }
        this.pararEscutar();
    }

    modalItemsEmProcessamento() {
        let dialogRef = this.dialog.open(ProcessamentoModalComponent, {
            hasBackdrop: true,
            width: "500px",
            height: "460px"
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) return;
        });
    }

    modalDetalhesTerminal(data: any) {
        let dialogRef = this.dialog.open(DetalhesTerminalModalComponent, {
            hasBackdrop: true,
            width: "900px",
            height: "690px",
            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) return;
        });
    }

    verificarSMSAtivo(terminal) {
        this.service.verificarSMSAtivo(terminal).subscribe((result) => {
            this._notification.success("telaConsultaTelefones.verificarSMSAtivo", "telaConsultaTelefones.aguardarVerificarSMSAtivo");

            console.log("Escutando VerificarSMSAtivo");
            this.escutarVerificarSMSAtivo();
        })
    }

    escutarVerificarSMSAtivo() {
        if (this.hubConnection.state == HubConnectionState.Disconnected)
            this.hubConnection.start().then(() => this.atualizarVerificarSMSAtivo());
        else
            this.atualizarVerificarSMSAtivo();
    }

    atualizarVerificarSMSAtivo() {
        this.hubConnection.invoke('JoinGroup', this.signalRGrupo);
        this.hubConnection.on('AtualizarVerificaSMSAtivo', dados => {
            if (dados) {
                var terminal = this.dados.find(t => t.terminal == dados.terminal);
                terminal.possuiSMSAtivo = dados.possuiSMSAtivo;
                this.hubConnection.off('ProcessamentoTerminal');
                this._notification.success("telaConsultaTelefones.verificarSMSAtivo", "telaConsultaTelefones.statusSMSAtualizado");
            }
        });
    }

    validarTelefone() {
        if (Object.keys(this.model).length == 0) {
            this.warnNotification("telaConsultaTelefones.atencao", "telaConsultaTelefones.consultaVazia");
            return false;
        }

        if (Object.keys(this.model).length > 0) 
            if (this.model.terminal == undefined || this.model.terminal == '') {
                this.warnNotification("telaConsultaTelefones.atencao", "telaConsultaTelefones.consultaVazia");
                return false;
            }

            if (this.model.terminal != undefined && this.model.terminal.length < 10) {
                this.warnNotification("telaConsultaTelefones.atencao", "telaConsultaTelefones.telefoneInvalido");
                return false;
            }
        
        return true;
    }

    gravarInputPesquisa() {
        localStorage.setItem('consulta-terminal', this.model.terminal);
    }

    buscarInputPesquisa() {
        const terminal = localStorage.getItem('consulta-terminal');
        if (terminal != '' && terminal != undefined)
            this.model.terminal = terminal;
    }

    limparInputPesquisa() {
        localStorage.removeItem('consulta-terminal');
    }
}