import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { ServicesModule } from './libs/services/services.module';
import { LoadingModule } from './libs/components/loading/loading.module';
import { TranslateLoaderFactory } from './libs/utils/multi-translate-http-loader';
import { ConfigProvider, APPCONFIG, ConfigProviderFactory } from './common/providers/config.provider';
import { BreadcrumbService } from './libs/components/custom-breadcrumb/breadcrumb.service';
import { LayoutDataService } from './services/layout-data.service';
import { AppRouteModule } from './app.routing';
import { UsuarioModule } from './modules/usuario/usuario.module';
import { SistemaMenuService } from './services/sistema-menu.service';
import { UsuarioService } from './services/usuario.service';
import { UsuarioTipoService } from './services/usuario-tipo.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as moment from 'moment';
import { BigTalkRequestInterceptor } from './interceptors/bigtalk-request.interceptor';
import { CustomModuleCollection as CustomModules } from "./modules";
import { AutenticadoGuard, NaoAutenticadoGuard } from './guards/index';

import { CustomBreadcrumbService } from './services/index';
import { AutenticacaoService } from './services/autenticacao.service';
import { ConsultaTelefonesService } from './services/consulta-telefones.service';
import { ArquivoService } from './services/arquivo.service';
import { DownloadService } from './services/download.service';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { DashboardService } from './services/dashboard.service';
import { ChartService } from './services/chart.service';
import { WebhookModule } from './modules/webhook/webhook.module';
import { ClienteWebhookService } from './services/clienteWebhook.service';
import { DashboardTerminalModule } from './modules/dashboard-terminal/dashboard-terminal.module';

registerLocaleData(localePt);
moment.locale('pt-BR');

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		LayoutModule,
		AppRouteModule,
		UsuarioModule,
		ServicesModule,
		DashboardModule,
		DashboardTerminalModule,
		LoadingModule,
		WebhookModule,
		...CustomModules,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	providers: [
		ConfigProvider,
		AutenticadoGuard,
		NaoAutenticadoGuard,
		SistemaMenuService,
		UsuarioService,
		UsuarioTipoService,
		DashboardService,
		ClienteWebhookService,
		AutenticacaoService,
		ConsultaTelefonesService,
		ArquivoService,
		DownloadService,
		ChartService,
		LayoutDataService,
		{ provide: LOCALE_ID, useValue: 'pt' },
		{ provide: BreadcrumbService, useClass: CustomBreadcrumbService },
		{ provide: HTTP_INTERCEPTORS, useClass: BigTalkRequestInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: ConfigProviderFactory, deps: [ConfigProvider], multi: true },
		{ provide: APPCONFIG, useFactory: (provider: ConfigProvider) => provider.getConfig(), deps: [ConfigProvider] }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
