import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import * as momentjs from 'moment';

const moment = momentjs;

@Directive({
  selector: '[tMaxTime][formControlName],[tMaxTime][formControl],[tMaxTime][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxTimeValidator, multi: true }]
})
export class MaxTimeValidator implements Validator {
  @Input() tMaxTime: string;

  validate(c: FormControl): { [key: string]: any } {
    const v: momentjs.Moment = moment(c.value, 'HH:mm');
    if (this.tMaxTime === '' || this.tMaxTime == null) { return null; }
    if (v == null || !v.isValid()) { return null; }
    return (v.isAfter(moment(this.tMaxTime, 'HH:mm'))) ?
      { 'maxTime': { max: moment(this.tMaxTime, 'HH:mm'), currentValue: c.value } } : null;
  }
}
