import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { convertToQueryString } from '../utils/query-string';
import { StorageProxy } from './storage-proxy.service';
import { IAppConfig, Login, AuthOptions, Credential, User } from '../../common/model';
import { APPCONFIG } from '../../common/providers/config.provider';
import { UserDataService } from './user-data.service';

@Injectable()
export class AuthenticationService extends UserDataService {

    constructor(protected httpClient: HttpClient, @Inject(APPCONFIG) protected appConfig: IAppConfig) {
        super();
    }

    autenticarLogin(login: Login, path: string = this.appConfig.tokenEndpoint): Observable<Credential> {
        const loginData = new AuthOptions(this.appConfig.clientId);
        loginData.setUser(login.login, login.senha);

        return this.gerarToken(path, loginData).pipe(
            tap(credential => (StorageProxy.set('userData', credential))));
    }

    autenticarRefreshToken(path: string = this.appConfig.tokenEndpoint): Observable<any> {
        const loginData = new AuthOptions(this.appConfig.clientId);
        const userData = StorageProxy.get<Credential>('userData');
        if (userData != null) {
            loginData.setRefreshToken(userData.refreshToken);
        }

        return this.gerarToken(path, loginData).pipe(
            tap(result => {
                const tokenData: any = { accessToken: result.accessToken, user: result.user };
                if (result.refreshToken) {
                    tokenData.refreshToken = result.refreshToken;
                }
                StorageProxy.set('userData', Object.assign({}, StorageProxy.get<Credential>('userData'), tokenData));
            }),
            catchError(err => {
                StorageProxy.set('userData', null);
                return throwError(err);
            })
        );
    }

    obterCredenciais(path: string = this.appConfig.credentialEndpoint) {
        return this.httpClient
            .get<any>(path)
            .pipe(
                tap(result => {
                    const user: User = {
                        id: result.user.id,
                        userLogin: result.user.userLogin,
                        userName: result.user.userName,
                        properties: result.user.properties
                    };

                    StorageProxy.set('userData', Object.assign({}, StorageProxy.get<Credential>('userData'), {
                        tokenType: result.tokenType,
                        user: user
                    }));
                })
            );
    }

    deslogarUsuario() {
        StorageProxy.set('userData', null);
    }

    protected gerarToken(path: string, loginData: AuthOptions) {
        return this.httpClient
            .post<Credential>(path,
                convertToQueryString(loginData, false),
                {
                    headers: new HttpHeaders().set(
                        'Content-Type',
                        'application/x-www-form-urlencoded'
                    )
                }
            );
    }
}
