import { Injectable } from '@angular/core';
import _ from 'underscore';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../libs/services/base.service';
import { Usuario } from '../models/usuario';
import { map } from 'rxjs/operators';
import { ClienteWebhook } from '../models/ClienteWebhook';

@Injectable()
export class ClienteWebhookService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http);
    }

    getWebhook(): Observable<ClienteWebhook> {
        return this.get(this._api + 'obter').pipe(map(webhook => ClienteWebhook.fromRaw(webhook)));
    }

    criarWebhook(clienteWebhook: any): Observable<any> {
        return this.post(this._api + 'Criar', clienteWebhook);
    }

    alterarWebhook(clienteWebhook: any): Observable<any> {
        return this.put(this._api + 'alterar/' + clienteWebhook.id , clienteWebhook);        
    }
}