import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import * as _ from 'underscore';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpErrorResponse,
    HttpEvent,
    HttpResponse
} from '@angular/common/http';

import { tap, catchError, concatMap } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { APPCONFIG } from '../../common/providers/config.provider';
import { AuthenticationService } from '../services/authentication.service';
import { IAppConfig } from '../../common/model';
import { LoadingService } from '../services/loading.service';
import { Router } from '@angular/router';
import { convertToModel } from '../utils/query-string';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    protected requestCounter = 0;

    constructor(
        protected authService: AuthenticationService,
        protected loading: LoadingService,
        protected alertService: AlertService,
        protected router: Router,
        @Inject(APPCONFIG) protected appConfig: IAppConfig) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // se for uma chamada a api, signalr ou api de token, concatenar a url do serviço antes da requisição
        if (req.url.startsWith('/api/') || req.url.startsWith(this.appConfig.tokenEndpoint)) {
            req = req.clone({ url: `${this.appConfig.serverUrl}${req.url}` });
        }

        // Adicionando a quantidade de requisições em fila
        this.startLoading();

        // Se está carregando um asset ou autenticando, realiza o request
        if (this.isLoadingAsset(req) || this.isAuthenticating(req)) {
            return next
                .handle(req)
                .pipe(
                    tap(ev => this.handleResponse(ev)),
                    catchError(err => this.handleError(err, req, next))
                );
        }

        if (this.authService.autenticado && this.authService.tokenExpirado) {
            return this.reauthenticateRefreshToken(req, next);
        } else {
            return this.continueRequest(req, next);
        }
    }

    protected reauthenticateRefreshToken(req, next) {
        return this.authService.autenticarRefreshToken().pipe(
            concatMap(val => this.continueRequest(req, next))
        );
    }

    protected continueRequest(req: HttpRequest<any>, next: HttpHandler) {
        return next
            .handle(this.addToken(req, this.authService.obterTokenAutenticado()))
            .pipe(
                tap(ev => this.handleResponse(ev)),
                catchError(err => this.handleError(err, req, next))
            );
    }

    protected handleResponse(ev: HttpEvent<any>) {
        if (!(ev instanceof HttpResponse)) {
            return;
        }
        this.stopLoading();
    }

    protected handleError(error: Error, req: HttpRequest<any>, next: HttpHandler) {
        this.stopLoading();
        if (error instanceof HttpErrorResponse) {
            switch ((error as HttpErrorResponse).status) {
                case 401:
                    const backToLogin = () => {
                        this.authService.deslogarUsuario();
                        this.router.navigate([this.appConfig.loginFrontUrl]);
                        return throwError(error);
                    };

                    const contentType = req.headers.get('content-type');
                    const tryingToReauth = contentType === 'application/x-www-form-urlencoded' && req.body.indexOf('refresh_token') >= 0;
                    if (!tryingToReauth) {
                        if (req.body.indexOf('grant_type=password') === -1) {
                            return this.reauthenticateRefreshToken(req, next);
                        } else {
                            return throwError(error);
                        }
                    } else {
                        return backToLogin();
                    }
                case 400:
                case 403:
                case 404:
                case 500:
                case 0:
                    return this.handleGenericError(error);
                default:
                    return throwError(error);
            }
        } else {
            return throwError(error);
        }
    }

    protected handleGenericError(httpError: HttpErrorResponse) {

        let message = httpError.message;
        if (httpError.error && httpError.error.errors) {
            message = this.concatMessages(httpError.error.errors);
        } else if (httpError.error && httpError.error.message) {
            message = httpError.error.message;
        }

        this.alertService.show(
            'erro.alertaErroTitulo',
            message,
            'error'
        );

        return throwError(httpError);
    }

    protected concatMessages(errors) {
        const messages: Array<string> = _.map(errors, error => error.message);
        return messages.join(' / ');
    }

    protected addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    protected startLoading() {
        setTimeout(() => {
            if (this.requestCounter === 0) {
                this.loading.open();
                this.requestCounter++;
            }
        }, 0);
    }

    protected stopLoading() {
        setTimeout(() => {
            if (this.requestCounter !== 0) {
                this.requestCounter--;
            }

            if (this.requestCounter === 0) {
                this.loading.close();
            }
        }, 0);
    }

    protected isLoadingAsset(req: HttpRequest<any>): boolean {
        return req.url.indexOf(this.appConfig.serverUrl) < 0;
    }

    protected isAuthenticating(req: HttpRequest<any>): boolean {
        return req.url.indexOf(this.appConfig.tokenEndpoint) >= 0;
    }
}
