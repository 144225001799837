import { Component, Input, OnDestroy, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { IAppConfig, Credential } from 'src/app/common/model';
import { AlertService } from '../../../../libs/services/alert.service';
import { APPCONFIG } from 'src/app/common/providers/config.provider';
import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { IndicadorArquivoResumo, IndicadorArquivoTipo, IndicadorArquivoDDD } from '../../../../models/indicador-arquivo';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { DownloadService } from 'src/app/services/download.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-processamento-step',
	templateUrl: 'processamento-step.component.html',
	styleUrls: ['processamento-step.component.scss']
})

export class ProcessamentoStepComponent implements OnInit, OnDestroy {
	@Input() terminalArquivo: any;
	@Output() finalizouProcessamentoEvent = new EventEmitter();
	@Output() aprovouArquivoEvent = new EventEmitter();

	indicadorResumo: IndicadorArquivoResumo;
	indicadorTipo: Array<IndicadorArquivoTipo>;
	indicadorDDD: Array<IndicadorArquivoDDD>;

	private _hubConnection: HubConnection | undefined;
	private _signalRGrupo: string;
	public percentualConclusao: number;


	constructor(private _arquivoService: ArquivoService,
		private _alertService: AlertService,
		private _autenticacaoService: AutenticacaoService,
		private _translateService: TranslateService,
		private _downloadService: DownloadService,
		private router: Router,
		@Inject(APPCONFIG) protected appConfig: IAppConfig
	) {
		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${this.appConfig.serverUrl}/notificacoesBigTalk`)
			.configureLogging(signalR.LogLevel.Information)
			.build();
		this._signalRGrupo = `cliente_${this._autenticacaoService.obterClienteIdUsuario()}`;
		this.escutarProcessamento();
	}

	ngOnInit() {
		this.indicadorResumo = new IndicadorArquivoResumo();
		this.indicadorTipo = new Array<IndicadorArquivoTipo>();
		this.indicadorDDD = new Array<IndicadorArquivoDDD>();
	}

	escutarProcessamento() {
		this._hubConnection.start().then(() => {
			this._hubConnection.invoke('JoinGroup', this._signalRGrupo);
			this._hubConnection.on('ProcessamentoArquivo', mensagem => {
				if (this.terminalArquivo && this.terminalArquivo.id === mensagem.terminalArquivoId) {
					if (mensagem.finalizouProcesso) {
						this.sleep(2);
						this.finalizouProcessamentoEvent.emit();
					} else {
						this.terminalArquivo.terminalArquivoStatusId = mensagem.terminalArquivoStatusId;
						this.percentualConclusao = mensagem.percentualConclusao;
					}
				}
			});
		});
	}

	ngOnDestroy() {
		this._hubConnection.stop();
	}

	public obterIndicadorProcessamento(terminalArquivoId: number) {
		this._arquivoService.obterIndicadorResumo(terminalArquivoId).subscribe(result => {
			this.indicadorResumo = result;
		});

		this._arquivoService.obterIndicadorTipo(terminalArquivoId).subscribe(result => {
			this.indicadorTipo = result;
		});

		this._arquivoService.obterIndicadorDDD(terminalArquivoId).subscribe(result => {
			this.indicadorDDD = result;
		});
	}

	sleep(seconds) {
		const e = new Date().getTime() + (seconds * 1000);
		while (new Date().getTime() <= e) { }
	}

	obterTotalTipo() {
		let total = 0;

		this.indicadorTipo.forEach(i => {
			total = total + i.quantidade;
		});

		return total;
	}

	aprovar() {
		this._alertService.confirmationMessage('arquivoImportacao.titulo', "arquivoImportacao.confirmaProcessamento", "telaPadrao.confirmar", "telaPadrao.cancelar")
			.then((result) => {
				if (result.value) {
					this._arquivoService.aprovarArquivo(this.terminalArquivo.id)
						.subscribe(() => {
							this.aprovouArquivoEvent.emit();
						});
				}
			});
	}

	reprovar() {
		this._alertService.confirmationMessage('arquivoImportacao.titulo', "arquivoImportacao.confirmaCancelamento", "telaPadrao.confirmar", "telaPadrao.cancelar")
			.then((result) => {
				if (result.value) {
					this._arquivoService.reprovarArquivo(this.terminalArquivo.id)
						.subscribe(() => {
							this.router.navigate(['/app/arquivo']);
						});
				}
			});
	}

	obterArquivoInvalidos(terminalArquivoId: number) {
		this._arquivoService.obterArquivoInvalidos(terminalArquivoId).subscribe(
			arquivo => {
				this._downloadService.downloadTxt(arquivo, this._translateService.instant('arquivoImportacao.downloadArquivoInvalidos'));
			}
		);
	}
}

