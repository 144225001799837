import { Component, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BaseControlComponent } from '../base-custom/base-control.component';

@Component({
  selector: 't-textarea',
  templateUrl: './custom-textarea.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomTextareaComponent, multi: true }
  ]
})
export class CustomTextareaComponent extends BaseControlComponent {

  @Input()
  required = false;
  @Input()
  minlength: number = null;
  @Input()
  maxlength: number = null;
  @Input()
  pattern: string = null;
  @Input()
  min: number = null;
  @Input()
  max: number = null;
  @Input()
  rows: number = null;
  @Input()
  cols: number = null;

  constructor(injector: Injector) { super(injector); }
}
