import { Directive, ElementRef, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { APPCONFIG } from '../../common/providers/config.provider';
import { IAppConfig } from '../../common/model/app-config';
import * as jquery from 'jquery';
const $ = jquery;

// Mobile only: automatically close sidebar on route change.
@Directive({ selector: '[myAutoCloseMobileNav]' })

export class AutoCloseMobileNavDirective implements OnInit {
  el: ElementRef;
  router: Router;
  constructor(el: ElementRef, router: Router, @Inject(APPCONFIG) private appConfig: IAppConfig) {
    this.el = el;
    this.router = router;
  }

  ngOnInit() {
    const $body = $('#body');

    if (this.appConfig.autoCloseMobileNav) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          setTimeout(() => {
            $body.removeClass('sidebar-mobile-open');
          }, 0);
        }
      });
    }

  }
}
