import { Injectable } from '@angular/core';
import { BaseService } from '../libs/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ArquivoService extends BaseService {
    constructor(protected http: HttpClient) {
        super(http);
        this._api = '/api/terminalArquivo';
    }

    public enviarArquivo(
        arquivo: any
    ): Observable<any> {
        const formData = new FormData();
        formData.append('arquivo', arquivo);
        return this.http.post(this._api + '/enviarArquivo/', formData);
    }

    public obterArquivoTemplate(): Observable<any> {
        return this.downloadFile(this._api + '/obterArquivoTemplate');
    }

    public obterArquivoEncontrados(terminalArquivoId: number): Observable<any> {
        return this.downloadFile(this._api + '/obterArquivoEncontrados/' + terminalArquivoId);
    }

    public obterArquivoNaoEncontrados(terminalArquivoId: number): Observable<any> {
        return this.downloadFile(this._api + '/obterArquivoNaoEncontrados/' + terminalArquivoId);
    }

    public obterArquivoInvalidos(terminalArquivoId: number): Observable<any> {
        return this.downloadFile(this._api + '/obterArquivoInvalidos/' + terminalArquivoId);
    }
    public obterArquivoEnviado(terminalArquivoId: number): Observable<any> {
        return this.downloadFile(this._api + '/obterArquivoEnviado/' + terminalArquivoId);
    }
    public getById(id: number): Observable<any> {
        return this.get(this._api + '/' + id);
    }

    public obterIndicadorResumo(terminalArquivoId: number) {
        return this.get(this._api + '/indicadorResumo/' + terminalArquivoId);
    }

    public obterIndicadorDDD(terminalArquivoId: number) {
        return this.get(this._api + '/indicadorDDD/' + terminalArquivoId);
    }

    public obterIndicadorTipo(terminalArquivoId: number) {
        return this.get(this._api + '/indicadorTipo/' + terminalArquivoId);
    }

    public obterIndicadorScore(terminalArquivoId: number) {
        return this.get(this._api + '/indicadorScore/' + terminalArquivoId);       
    }

    public aprovarArquivo(terminalArquivoId: number): Observable<any> {
        return this.get(this._api + '/aprovar/' + terminalArquivoId);
    }

    public reprovarArquivo(terminalArquivoId: number): Observable<any> {
        return this.get(this._api + '/reprovar/' + terminalArquivoId);
    }
}