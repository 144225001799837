import { Component, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BaseControlComponent } from '../base-custom/base-control.component';

@Component({
  selector: 't-checkbox',
  templateUrl: './custom-checkbox.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomCheckboxComponent, multi: true }
  ],
})
export class CustomCheckboxComponent extends BaseControlComponent {

  @Input()
  label: string;

  @Input()
  required = false;

  @Input()
  color = 'accent';

  constructor(injector: Injector) { super(injector); }
}
