import * as validate from 'validate.js';

export class ClienteWebhook {

    id: number = 0;
    tipoAutenticacao?: number;
    url: string = null;
    usuario: string = null;
    senha: string = null;
    apiKey: string = null;
    urlLogin: string = null;
    verboLogin: string = null;
    clientId: string = null;
    clientSecret: string = null;
    clientScope: string = null;
    grantType: string = null;
    ativar: boolean = false;

    constructor() {
    }
    
    static fromRaw(rawUsuario): ClienteWebhook {
		return Object.assign(new ClienteWebhook(), rawUsuario);
	}

	validate() {
		validate.validators.semEspacos = function (value) {
			if (!value || value.indexOf(" ") == -1) return null;
			return "^espaco";
        };
        
		validate.validators.senhaIgual = function (value, options, key, usuario) {
			if (usuario.senha != value) return "^senhasDiferentes";
			return null;
        };  

        let regras = {
			url: {
				presence: { allowEmpty: false, message: "^obrigatorio" }
            },
            tipoAutenticacao: {
                presence: { allowEmpty: false, message: "^obrigatorio" }
            }
        };

        this.selecionarTipo(this.tipoAutenticacao);
        if (this.tipoAutenticacao == 1) {
            regras["usuario"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
            regras["senha"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
        }
        if (this.tipoAutenticacao == 2) {
            regras["apiKey"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
        }
        if (this.tipoAutenticacao == 3) {
            regras["urlLogin"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
            regras["verboLogin"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
            regras["clientId"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
            regras["clientSecret"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
            regras["clientScope"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
            regras["grantType"] = { presence: { allowEmpty: false, message: "^obrigatorio"} };
        }

		return validate.validate(this, regras);
    }
    
    selecionarTipo(tipoAuth: number){
        if(tipoAuth == 1){
            this.apiKey = null;
            this.urlLogin = this.verboLogin = this.clientId = this.clientSecret = this.clientScope = this.grantType = null;
        }   
        if(tipoAuth == 2){
            this.usuario = this.senha = null;
            this.urlLogin = this.verboLogin = this.clientId = this.clientSecret = this.clientScope = this.grantType = null;
        }
        if(tipoAuth == 3){
            this.apiKey = null;
            this.usuario = this.senha = null;
        }
    }
}