import { BaseDependenciesModule } from '../libs/base/base-dependencies.module';
import { FormatPipeModule } from '../libs/pipes/format-pipes/format-pipes.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MatTooltipModule, MatButtonModule,MatFormFieldModule, MatCardModule, MatIconModule,  MatStepperModule, MatInputModule, MatProgressSpinnerModule, MatSlideToggleModule } from '@angular/material';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'primeng/fileupload';
import { numberFormatPipe } from '../pipes/number-format.pipe';
import { SuffixNumberPipe } from '../pipes/suffix-number.pipe'
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BaseDependenciesModule,
    FormatPipeModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatButtonModule,
    TableModule,
    TranslateModule,
    MatStepperModule,
    FileUploadModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    numberFormatPipe,
    SuffixNumberPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BaseDependenciesModule,
    FormatPipeModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    TableModule,
    TranslateModule,
    MatStepperModule,
    FileUploadModule,
    ReactiveFormsModule,
    MatInputModule,
    numberFormatPipe,
    SuffixNumberPipe,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ]
})
export class DependenciesModule { }
