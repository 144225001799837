import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { FooterModule } from './components/footer/footer.module';
import { HeaderModule } from './components/header/header.module';
import { SidenavModule } from './components/sidenav/sidenav.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from '@angular/common/http';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { DependenciesModule } from '../shared/dependencies.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FooterModule,
    HeaderModule,
    SidenavModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SimpleNotificationsModule,
    DependenciesModule
    
  ],
  declarations: [
    LayoutComponent,
  ],
  exports: [
    LayoutComponent
  ]
})

export class LayoutModule { }
