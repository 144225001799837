import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServicesModule } from '../../libs/services/services.module';
import { DependenciesModule } from '../../shared/dependencies.module'
import { DocumentacaoListComponent } from './list/documentos.list.component';

import {
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDatepickerModule,
    MatTooltipModule
} from "@angular/material";



@NgModule({
    imports: [
        DependenciesModule,
        BrowserAnimationsModule,
        ServicesModule,

        MatCardModule,
        MatTabsModule,
        MatDatepickerModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatButtonModule,
        MatListModule,
        MatDialogModule,
        MatIconModule,
        MatDividerModule,
        MatSelectModule,
        MatTooltipModule,
    ],
    declarations: [
        DocumentacaoListComponent
    ],
    exports: [
        DocumentacaoListComponent
    ]
})
export class DocumentacaoModule { }