import { ServicesModule } from '../libs/services/services.module';
import { TranslateService } from "@ngx-translate/core";
import * as $ from 'jquery';
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";

export class ChartService {

    definirIdiomaCharts() {
        let translateService = ServicesModule.injector.get(TranslateService);

        switch (translateService.currentLang) {
            case 'pt-BR':
                return am4lang_pt_BR;

            case 'en':
                return am4lang_en_US;

            case 'es':
                return am4lang_es_ES;

            default:
                return am4lang_pt_BR;
        }
    }

    removerMarcaDagua() {
        setTimeout(() => {
          var elements = $('title:contains("Chart created using amCharts library")');
    
          elements.each(function (index) {
            var parent = elements.parent();
            parent.remove();
          });
    
          this.removerMarcaDagua();
        }, 1000);
      }    
}