import { Injectable, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { BaseController } from './base.controller';
import { BaseService } from '../services/base.service';
import { ServicesModule } from '../services/services.module';
import { NgForm } from '@angular/forms';

@Injectable()
export class DetalheBaseController extends BaseController implements OnDestroy, OnInit {
    _route: ActivatedRoute;
    _location: Location;

    modelLoaded: EventEmitter<any> = new EventEmitter();
    type: string;
    showForm = false;
    subGet: Subscription;
    subPut: Subscription;
    subPost: Subscription;
    id: any;

    constructor(_route: ActivatedRoute, _service: BaseService) {
        super(_service);
        this._route = _route;
        this.type = this._route.snapshot.data['type'];
        this._location = ServicesModule.injector.get(Location);
    }

    ngOnInit() {
        if (this.type === 'edit' || this.id != null) {
            const id = this._route.snapshot.params['id'] || this.id;
            if (id === undefined || id === '') { return; }

            this.subGet = this._service.get(this._service._api, id).subscribe(result => {
                this.model = result;
                this.showForm = true;
                this.modelLoaded.emit({});
            });
        } else {
            this.showForm = true;
        }
    }

    public salvar(form: NgForm) {
        if (!this.validate(form)) {
            return;
        }

        const formValue = form.value;
        const type = this._route.snapshot.data['type'];

        const sucessCallback = () => {
            const alert = { title: this._sucessoTitle, message: this._sucessoMessage };
            localStorage.setItem('msgSucesso', JSON.stringify(alert));
            this._location.back();
        };

        if (type === 'edit') {
            formValue.id = this.model.id;
            this.subPut = this._service.putId(this._service._api, this.model.id, formValue)
                .subscribe(sucessCallback);
        } else {
            this.subPost = this._service.post(this._service._api, formValue)
                .subscribe(sucessCallback);
        }
    }

    ngOnDestroy() {
        if (this.subGet) { this.subGet.unsubscribe(); }
        if (this.subPut) { this.subPut.unsubscribe(); }
        if (this.subPost) { this.subPost.unsubscribe(); }
    }
}
