import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { OrderLabel } from '../../../common/model';

const nomeCrescente = 'Crescente';
const nomeDecrescente = 'Decrescente';
const valorCrescente = 'asc';
const valorDecrescente = 'desc';

function toInt(text: string) {
  return parseInt(text.replace('.', ''), 10);
}

@Component({
  selector: 't-listbox',
  styleUrls: ['./custom-listbox.component.scss'],
  templateUrl: './custom-listbox.component.html'
})
export class CustomListboxComponent implements OnInit {
  @Input() focus: boolean;
  @ViewChild('input', { static: false }) input: any;
  @Input() label: string;
  @Input() value: string;
  @Input() orderLabels: OrderLabel;
  @Input() placeholder = 'Selecione';
  @Input() noDataLabel = 'Sem dados';
  @Input() model;
  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() showToggleAll: boolean;
  @Input() enableOrderBy = false;

  @Output() modelChange: EventEmitter<any>;
  @Output() changed: EventEmitter<any>;

  _data: Array<any>;
  formatedData: Array<any>;
  filter: string;
  orderBy;
  listaTipoOrdenacao = [
    {
      nome: this.orderLabels.nomeCrescente || nomeCrescente,
      func: (data: Array<any>) =>
        data.sort((a, b) => a.label.localeCompare(b.label))
    },
    {
      nome: this.orderLabels.nomeDecrescente || nomeDecrescente,
      func: (data: Array<any>) =>
        data.sort((a, b) => b.label.localeCompare(a.label))
    },
    {
      nome: this.orderLabels.valorCrescente || valorCrescente,
      func: (data: Array<any>) =>
        data.sort((a, b) => toInt(a.suffix) - toInt(b.suffix))
    },
    {
      nome: this.orderLabels.valorDecrescente || valorDecrescente,
      func: (data: Array<any>) =>
        data.sort((a, b) => toInt(b.suffix) - toInt(a.suffix))
    }
  ];

  @Input()
  public set data(data: Array<any>) {
    if (data == null) { return; }
    this._data = data;
    this.formatedData = this.data.map(item =>
      Object.assign(
        {
          label: item[this.label],
          value: item[this.value]
        },
        item
      )
    );
  }

  public get filteredFormatedData() {
    if (!this.filter) { return this.formatedData; }
    return this.formatedData.filter(
      c => c.label.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1
    );
  }

  public get data() {
    return this._data;
  }

  constructor() {
    this.formatedData = [];
    this.modelChange = new EventEmitter<any>();
    this.changed = new EventEmitter<any>();
  }

  isChecked(item) {
    if (Array.isArray(this.model)) { return this.model.find(c => c === item); }
    return false;
  }

  changeValue(data) {
    this.modelChange.emit(data);
    this.changed.emit(data);
  }

  changeArray(item, event) {
    if (Array.isArray(this.model) === false) { this.model = []; }
    let data = this.model.map(c => c);
    if (event.checked) { data.push(item); } else { data = data.filter(c => c !== item); }

    this.modelChange.emit(data);
    this.changed.emit(data);
  }

  changeOrder() {
    const query = this.listaTipoOrdenacao.find(c => c.nome === this.orderBy);
    this.formatedData = query.func(this.formatedData);
  }

  ngOnInit() {
    if (this.focus) { setInterval(() => this.input.nativeElement.focus(), 0); }
  }
}
