import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClienteWebhookService } from 'src/app/services/clienteWebhook.service';
import { ClienteWebhook } from 'src/app/models/ClienteWebhook';
import { DetalheBaseController } from 'src/app/libs/base/detalhe.base.controller';

@Component({
  selector: 'app-configuracao-webhook',
  templateUrl: './configuracao-webhook.component.html',
  styleUrls: ['./configuracao-webhook.component.scss']
})
export class ConfiguracaoWebhookComponent extends DetalheBaseController implements OnInit {

  tipos: any = [
    { id: 1, descricao: 'Basic' },
    { id: 2, descricao: 'ApiKey' },
    { id: 3, descricao: 'OAuth' },
    { id: 4, descricao: 'Anônimo' }
  ];
  constructor(private route: ActivatedRoute,
    private clienteWebhookService: ClienteWebhookService,
    private router: Router) {
    super(route, clienteWebhookService)
    this.model = new ClienteWebhook();
    this._service._api = '/api/clientewebhook/';
  }

  ngOnInit() {
    this.model.tipoAutenticacao = 4;
    this.clienteWebhookService.getWebhook().subscribe(result => {
      if(result){
        this.model = result;
      }
    })
  }
  salvar() {
		this._submitted = true;
    if (this.model.validate()) {
      return;
    }else{
      if(this.model.id == 0){
        this.clienteWebhookService.criarWebhook(this.model).subscribe(result => {
          this._notification.success('Webhook', 'Configuração salva com sucesso!');
        });
      }else{
        this.clienteWebhookService.alterarWebhook(this.model).subscribe(result => {
          this._notification.success('Webhook', 'Configuração salva com sucesso!');
        });
      }
    }
  }

}
