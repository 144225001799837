
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { AutenticadoGuard } from "./guards/autenticado.guard";
import { LoginComponent } from "./modules/autenticacao/login/login.component";
import { ConsultaTelefonesListaComponent } from './modules/consulta-telefones/list/consulta-telefones.lista.component';
import { UsuarioListComponent } from './modules/usuario/list/usuario.list.component';
import { UsuarioEditComponent } from './modules/usuario/edit/usuario.edit.component';
import { ArquivoListaComponent } from './modules/arquivo/lista/arquivo.lista.component';
import { ArquivoImportacaoComponent } from './modules/arquivo/importacao/arquivo.importacao.component';
import { DocumentacaoListComponent } from './modules/documentacao/list/documentos.list.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { AlterarSenhaComponent } from './modules/autenticacao/alterar-senha/alterar-senha.component';
import { ConfiguracaoWebhookComponent } from "./modules/webhook/configuracao/configuracao-webhook.component";
import { DashboardTerminalComponent } from "./modules/dashboard-terminal/dashboard-terminal.component";

export const appRoutes: Routes = [
    { path: "", redirectTo: "app/dashboard", pathMatch: "full" },
    { path: "login", component: LoginComponent },
    { 
        path: 'app', component: LayoutComponent, canActivate: [AutenticadoGuard], data: { title: 'BigTalk' },
        children: [
             {
                path: "",
                redirectTo: "/app/dashboard",
                pathMatch: "full",
                canActivate: [AutenticadoGuard]
            },
            {
                path: 'alterarsenha',
                component: AlterarSenhaComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.alterarSenha' }
            },            
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.dashboard' }
            },
            {
                path: 'consultatelefones',
                component: ConsultaTelefonesListaComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.consultaTelefones' }
            },
            {
                path: 'consultatelefones/:terminal',
                component: ConsultaTelefonesListaComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.consultaTelefones' }
            },
            {
                path: 'docs',
                component: DocumentacaoListComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.documentacao' }
            },
            {
                path: 'webhook',
                component: ConfiguracaoWebhookComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.webhook' }
            },
            {
                path: 'usuario',
                component: UsuarioListComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.usuarios' }
            },
            {
                path: 'usuario/novo',
                component: UsuarioEditComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.cadastrarUsuario', parent: "usuario" }
              },            
            {
                path: 'usuario/:id',
                component: UsuarioEditComponent,
                canActivate: [AutenticadoGuard],
                data: { title: 'rotasTitulo.editarUsuario', parent: "usuario" }
            },
            {
                path: 'arquivo',
                component: ArquivoListaComponent, data: { title: 'rotasTitulo.arquivoLista' },
                canActivate: [AutenticadoGuard]
            },
            {
                path: 'arquivo/novo',
                component: ArquivoImportacaoComponent, data: { title: 'rotasTitulo.arquivoImportacao', 'type': 'new' },
                canActivate: [AutenticadoGuard]
            },
            {
                path: 'arquivo/:id',
                component: ArquivoImportacaoComponent, data: { title: 'rotasTitulo.arquivoImportacao', 'type': 'edit' },
                canActivate: [AutenticadoGuard]
            },
            {
                path: 'dashboard-terminal',
                component: DashboardTerminalComponent, data: { title: 'rotasTitulo.dashboardTerminal' },
                canActivate: [AutenticadoGuard]
            }
        ]
    }
];

export const AppRouteModule = RouterModule.forRoot(appRoutes);
