import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deepMerge } from './deep-merge';

export function TranslateLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' }
    ]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {

    constructor(private http: HttpClient,
        public resources: { prefix: string, suffix: string }[] = [{
            prefix: '/assets/i18n/',
            suffix: '.json'
        }]) { }

    /**
     * Gets the translations from the server
     * @param lang
     * @returns {any}
     */
    public getTranslation(lang: string): Observable<object> {
        return forkJoin(this.resources.map(config => {
            return this.http.get(`${config.prefix}${lang}${config.suffix}`);
        })).pipe(map(response => {
            return response.reduce((a, b) => {
                return deepMerge(a, b);
            });
        }));
    }
}