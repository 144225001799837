import { Observable } from 'rxjs';
import { convertToQueryString } from '../utils/query-string';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BaseService {
    _prefixApi = '';
    _api = '';

    constructor(protected http: HttpClient) { }

    get(path: string, params: string = ''): Observable<any> {
        return this.http.get(`${this._prefixApi}${path}${params}`, { responseType: 'json' });
    }

    downloadFile(path: string, headers: any = {}): Observable<Blob> {
        return this.http.get(`${this._prefixApi}${path}`, { headers, responseType: 'blob' });
    }

    getFiltro(path: string, model: any, pagina: number, tamanho: number): Observable<any> {
        return this.http.get(
            `${this._prefixApi}${path}${convertToQueryString(model, true)}&start=${pagina}&limit=${tamanho}`,
            { responseType: 'json' });
    }

    post(path: string, model: any): Observable<any> {
        return this.http.post(`${this._prefixApi}${path}`, JSON.stringify(model),
        { headers: { 'Content-Type': 'application/json; charset=utf-8' }, responseType: 'json' });
    }

    put(path: string, model: any = null): Observable<any> {
        return this.http.put(`${this._prefixApi}${path}`, JSON.stringify(model),
        { headers: { 'Content-Type': 'application/json; charset=utf-8' }, responseType: 'json' });
    }

    putId(path: string, id: number, model: any): Observable<any> {
        return this.put(path + id, model);
    }

    delete(path: string, id: number): Observable<any> {
        return this.http.delete(`${this._prefixApi}${path}${id}`, { responseType: 'json' });
    }

    inativar(id): Observable<any> {
        return this.http.put(`${this._prefixApi}${this._api}inativar/${id}`, '{}', { responseType: 'json' });
    }

    ativar(id): Observable<any> {
        return this.http.put(`${this._prefixApi}${this._api}ativar/${id}`, '{}', { responseType: 'json' });
    }
}
