import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { LayoutDataService } from '../../../services/layout-data.service';
import { APPCONFIG } from '../../../common/providers/config.provider';
import { LayoutHeaderMenu, IAppConfig, User, Login, AuthOptions, Credential } from '../../../common/model';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { MatMenu, MatMenuTrigger } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AutenticacaoService } from '../../../services/autenticacao.service';
import { StorageProxy } from 'src/app/libs/services/storage-proxy.service';
import { ServicesModule } from 'src/app/libs/services/services.module';

@Component({
	selector: 't-header',
	styles: [],
	templateUrl: './header.component.html'
})

export class AppHeaderComponent implements OnInit {
	public menusContexto: Array<LayoutHeaderMenu> = [];
	public usuario: User;
	logoUrl: string;

	_autenticacaoService: AutenticacaoService;
	_novidades: boolean = false;
	_terminal: Array<any> = [];
	_signalRGrupo: string = '';

	public _hubConnection: HubConnection | undefined;

	@ViewChild(MatMenu, { static: true }) appNotification: MatMenu;
	@ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
	constructor(private dataService: LayoutDataService,
		private translate: TranslateService,
		@Inject(APPCONFIG) public appConfig: IAppConfig) {
		this._autenticacaoService = ServicesModule.injector.get(AutenticacaoService);

		if (this._autenticacaoService.autenticado) {

			let user = StorageProxy.get<Credential>('userData').user.properties["clienteId"];
			this._signalRGrupo = `cliente_${user}`;

			this._hubConnection = new HubConnectionBuilder()
				.withUrl(`${this.appConfig.serverUrl}/notificacoesBigTalk`)
				.configureLogging(signalR.LogLevel.Information)
				.build();

			console.log("Escutando ProcessamentoTerminal");
			this.escutar();
		}
	}

	public limparNovidades() {
		this._novidades = false;
	}

	public escutar() {
		return this._hubConnection.start().then(() => {
			this._hubConnection.invoke('JoinGroup', this._signalRGrupo);
			this._hubConnection.on('ProcessamentoTerminal', dados => {
				//this.matMenuTrigger.openMenu();

				if (dados) {
					switch (dados.notificacaoTipo) {
						case 1:
							this._terminal.push({
								"titulo": `${dados.terminal} ${this.translate.instant('notificacoes.processado')}`,
								"mensagem": `${this.translate.instant('notificacoes.irConsulta')}.`,
								"terminal": dados.terminal
							});
							break;
						case 2:
							this._terminal.push({
								"titulo": `${dados.terminal} ${this.translate.instant('notificacoes.reprocessado')}`,
								"mensagem": `${this.translate.instant('notificacoes.irConsulta')}.`,
								"terminal": dados.terminal
							});
							break;
					}

					this._novidades = true;
				}
			});
		});
	}

	public pararEscutar() {
		this._hubConnection.invoke('LeaveGroup', this._signalRGrupo);
		this._hubConnection.stop();
	}



	ngOnInit() {
		this.usuario = this.dataService.getUser();
		this.menusContexto = this.dataService.getHeaderContextMenus();
		this.logoUrl = this.dataService.getLogoPath();
	}

	toggleCollapsedNav() {
		this.appConfig.navCollapsed = !this.appConfig.navCollapsed;
	}
}
