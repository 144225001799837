import { Injectable } from '@angular/core';

@Injectable()
export class DownloadService {
    constructor() {
    }

    downloadTxt(arquivo: any, nomeArquivo: string) {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = nomeArquivo;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
