import { Component, Inject, OnInit, LOCALE_ID } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DashboardService } from "../../../services/dashboard.service";
import { formatDate } from "@angular/common";
import { DashboardTerminalService } from "src/app/services/dashboard-terminal.service";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
@Component({
    selector: "app-dashboard-terminal-modal.component",
    templateUrl: "./dashboard-terminal-modal.component.html",
    styleUrls: ["./dashboard-terminal-modal.component.scss"],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ]
})
export class DashboardTerminalModalComponent implements OnInit {

    quantidade: any;
    terminalDia: any;
    mostrarDados: any = false;

    constructor(private ref: MatDialogRef<DashboardTerminalModalComponent>,
        private service: DashboardService,
        private dashboardTerminalService: DashboardTerminalService,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.terminalDia = {};

        if (this.data && this.data.data) {
            const data = this.data.data;
            const split = data.split("/");
            const ano = new Date().getFullYear();
            const novaData = ano.toString() + '-' + split[1] + '-' + split[0];
            this.data.dataFormatada = novaData;
            this.quantidade = this.data.quantidade;

            this.pesquisarResumoDetalhadoDia();
        }
    }

    ngOnInit() { }

    pesquisarResumoDetalhadoDia() {
        let filtroResumo = {
            dataI: undefined,
            dataF: undefined
        }

        filtroResumo.dataI = formatDate(this.data.dataFormatada, 'yyyy-MM-dd', this.locale);
        filtroResumo.dataF = formatDate(this.data.dataFormatada, 'yyyy-MM-dd', this.locale);

        this.dashboardTerminalService.obterResumoDetalhado(filtroResumo).subscribe(result => {
            this.terminalDia = result;
            this.mostrarDados = true;
        });
    }

    close() {
        this.ref.close();
    }
}